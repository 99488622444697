var baseURL;

import Vue from 'vue';

import Dropzone from 'vue2-dropzone';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import {
  camelResponse
} from 'axios-case-converter';

baseURL = process.env.VUE_APP_SERVER_URL;

export default {
  components: {Dropzone},
  props: {
    selectedDocumentId: Number
  },
  data: function() {
    return {
      dropzoneOptions: {
        url: `${baseURL}/documents`,
        withCredentials: true,
        params: {
          account_id: this.$route.params.id
        },
        acceptedFiles: 'image/jpeg, image/jpg, image/png, image/gif, application/pdf',
        thumbnailWidth: 300,
        thumbnailHeight: 300,
        dictDefaultMessage: "<i class='fas fa-cloud-upload'></i>Drop files here to upload",
        previewTemplate: `<div class="dz-preview dz-file-preview">
  <div class="dz-image">
    <img data-dz-thumbnail />
  </div>
  <div class="dz-icon"><i class="fas fa-file"></i></div>
  <div class="dz-details">
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
</div>`
      }
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    }
  },
  watch: {
    'account.documents': function() {
      return this.loadDocumentsFromServer();
    },
    '$route': {
      immediate: true,
      handler: function() {
        if (this.$refs.dropzone) {
          return this.$refs.dropzone.dropzone.options.params.account_id = this.$route.params.id;
        }
      }
    }
  },
  methods: {
    onfileAdded: function(file) {
      var previewContainer;
      // prepend (instead of append) new uploads
      previewContainer = file.previewElement.parentElement;
      previewContainer.insertBefore(file.previewElement, previewContainer.firstChild);
      // open document in JSPanel when clicked
      return file.previewElement.addEventListener('click', (e) => {
        var documentId, imageSrc, list, params, slot, want;
        imageSrc = e.currentTarget.querySelector('.dz-image img').src;
        params = new URLSearchParams(imageSrc.split('?')[1]);
        if (params.has('documentId')) {
          // document is being manually loaded from the server
          documentId = parseInt(params.get('documentId'));
        } else {
          // document was just uploaded to the server
          documentId = parseInt(file.previewElement.getAttribute('data-document-id'));
        }
        list = this.get("/ui/panels");
        want = `document-details-panel-${documentId}`;
        slot = list.findIndex((e) => {
          return e.id === want;
        });
        if (!~slot) {
          return this.run('/ui/openPanel', {
            id: `document-details-panel-${documentId}`,
            documentId: documentId,
            type: 'documentDetails'
          });
        }
      });
    },
    onSuccess: function(file, response) {
      var image, list;
      response = camelResponse(response);
      // unshift upload event to timeline
      this.account.userIds[response.event.userId] = this.get('/user/user.name');
      list = this.get('/accounts/account.events', []);
      list.unshift(response.event);
      // assign document id to preview element
      file.previewElement.setAttribute('data-document-id', response.document.id);
      // when uploading PDFs, display serverside generated thumbnail
      if (file.type === 'application/pdf') {
        image = file.previewElement.querySelector('.dz-image img');
        image.src = Vue.filter('format')(response.document.thumbnailUrl, 'fullUrl');
        file.previewElement.classList.remove('dz-file-preview');
        return file.previewElement.classList.add('dz-image-preview');
      }
    },
    loadDocumentsFromServer: function() {
      var document, i, len, ref, results;
      this.$refs.dropzone.removeAllFiles();
      ref = this.account.documents;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        document = ref[i];
        // set type to 'image/jpg' and size to 10KB to force PDF thumbnails to be shown
        results.push(this.$refs.dropzone.manuallyAddFile({
          name: document.title,
          type: 'image/jpg',
          size: 10000
        }, Vue.filter('format')(`${document.thumbnailUrl}?documentId=${document.id}`, 'fullUrl')));
      }
      return results;
    }
  }
};
