import Utils from '~utils';

import Autosize from 'autosize';

import {
  Money
} from 'v-money';

export default {
  components: {Money},
  props: {
    input: {
      type: String | Number,
      default: ''
    },
    type: String,
    label: String,
    hideLabel: Boolean,
    placeholder: String,
    rows: Number,
    mask: {
      type: String,
      default: ''
    },
    size: String,
    outline: {
      type: Boolean,
      default: false
    },
    darkTheme: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    required: Boolean
  },
  data: function() {
    return {
      model: this.input,
      ref: null,
      valid: true
    };
  },
  computed: {
    classes: function() {
      return {
        'placeholder': this.type === 'placeholder',
        'search': this.type === 'search',
        'size-full-width': this.size === 'fullWidth',
        'outline': this.outline,
        'dark-theme': this.darkTheme,
        'error': !this.valid,
        'required': this.required
      };
    }
  },
  watch: {
    input: function(input) {
      this.model = input;
      if (this.type === 'textarea') {
        return this.$nextTick(function() {
          return Autosize.update(this.$refs.textarea);
        });
      }
    },
    model: function(model) {
      return this.$emit('update:input', model);
    }
  },
  mounted: function() {
    if (this.type === 'textarea') {
      this.ref = this.$refs.textarea;
    } else {
      this.ref = this.$refs.input;
    }
    if (this.type === 'textarea') {
      Autosize(this.ref);
    }
    if (this.type === 'email' || this.type === 'password') {
      return this.ref.type = this.type;
    }
  },
  methods: {
    clearInput: function() {
      this.model = '';
      return this.ref.focus();
    },
    onFocus: function() {
      return this.valid = true;
    },
    onBlur: function() {
      return this.check();
    },
    check: function(form) {
      var ref, ref1, ref2, value, year;
      if (!((ref = this.model) != null ? ref.length : void 0)) {
        return this.valid = !form || !this.required;
      }
      switch (this.type) {
        case 'email':
          if (/^[^\s@]+@[^\s@]+/.test(this.model)) {
            return this.valid = !!(this.model = this.model.toLowerCase());
          }
          break;
        case 'name':
          return this.valid = !!(this.model = this.model.namify());
        case 'phone':
          return this.valid = !!((value = Utils.phone(this.model)) ? this.model = value : void 0);
        case 'day':
          return this.valid = (1 <= (ref1 = this.model) && ref1 <= 31);
        case 'dob-year':
          year = new Date().getFullYear();
          return this.valid = ((year - 120) <= (ref2 = this.model) && ref2 <= year);
        default:
          return this.valid = true;
      }
      return this.valid = false;
    }
  }
};
