import Header from './Header';

import Sidebar from './Sidebar';

import Drawer from './Drawer';

import Panels from './Panels';

import AccountNew from '../accounts/AccountNew';

export default {
  name: 'App',
  components: {Header, Sidebar, Drawer, Panels, AccountNew},
  computed: {
    user: function() {
      return this.get('/user/user');
    }
  },
  created: function() {
    if (!(this.user && Object.keys(this.user).length)) {
      return this.run('/user/load');
    }
  },
  beforeDestroy: function() {
    return this.run('/ui/closePanels');
  }
};
