import {
  MDCRadio
} from '@material/radio';

import {
  MDCFormField
} from '@material/form-field';

export default {
  props: {
    name: String,
    legend: String,
    input: String,
    inputs: Array,
    inline: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      model: this.input
    };
  },
  watch: {
    input: function(model) {
      return this.model = model;
    }
  },
  mounted: function() {
    return this.inputs.forEach((input) => {
      var formField, radio;
      radio = new MDCRadio(document.querySelector(`#${input.id}`));
      formField = new MDCFormField(document.querySelector(`#${input.id}-form-field`));
      return formField.input = radio;
    });
  },
  methods: {
    onChange: function() {
      return this.$emit('update:input', this.model);
    }
  }
};
