import Vue from 'vue';

import accounts from './accounts';

import auth from './auth';

import config from './config';

import ui from './ui';

import user from './user';

export default new Vue({
  data: function() {
    return {accounts, auth, config, ui, user};
  },
  computed: {
    foo: function() {
      return this.get('/config.a');
    },
    bar: function() {
      return this.get('/config.c.d');
    }
  },
  methods: {
    helpful: function(...args) {
      return p(['helpful', ...args]);
    }
  }
});
