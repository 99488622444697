export default {
  computed: {
    page: function() {
      return this.get('/accounts/page');
    },
    show: function() {
      return this.get('/accounts/show');
    },
    size: function() {
      return this.get('/accounts/size');
    },
    showing: function() {
      return `${(this.page * this.show) - (this.show - 1)} - ${this.page * this.show > this.size ? this.size : this.page * this.show}`;
    }
  }
};
