var words;

import jQuery from 'jquery';

words = function(str) {
  return str.trim().split(/\s+/);
};

export default {
  props: {
    editor: Object,
    input: String,
    toolbarInitiallyVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      model: this.input,
      config: {
        charCounterCount: false,
        enter: jQuery.FroalaEditor.ENTER_BR,
        events: {
          'froalaEditor.initialized': (e, editor) => {
            if (!this.toolbarInitiallyVisible) {
              editor.toolbar.hide();
            }
            return this.$emit('update:editor', editor);
          },
          'froalaEditor.toolbar.hide': (e, editor) => {
            return jQuery(editor.$el).removeClass('toolbar-visible');
          },
          'froalaEditor.toolbar.show': (e, editor) => {
            return jQuery(editor.$el).addClass('toolbar-visible');
          }
        },
        heightMin: 160,
        iconsTemplate: 'font_awesome_5r',
        imageDefaultAlign: 'left',
        placeholderText: 'Enter a message',
        toolbarBottom: true,
        toolbarButtons: words(`fontFamily fontSize color | bold italic underline |
paragraphFormat align formatOL formatUL outdent indent quote | clearFormatting`),
        toolbarSticky: false,
        pluginsEnabled: ['colors', 'fontFamily', 'fontSize', 'lists', 'link', 'image', 'file']
      }
    };
  },
  watch: {
    model: function(model) {
      return this.$emit('update:input', model);
    }
  }
};
