import Utils from '~/utils';

export default {
  props: {
    insideTable: Boolean
  },
  data: function() {
    return {
      valid: false
    };
  },
  methods: {
    check: function(form = true) {
      var anon, child, i, len, list, ref, text;
      list = [];
      anon = 0;
      ref = this.$children;
      for (i = 0, len = ref.length; i < len; i++) {
        child = ref[i];
        if ((typeof child.check === "function" ? child.check(form) : void 0) === false) {
          if (child.label) {
            list.push(child.label);
          } else {
            anon++;
          }
        }
      }
      if (anon) {
        list.push(`${anon} more field${anon === 1 ? '' : 's'}`);
      }
      return this.valid = list.length ? (text = Utils.commafy(list, ' required', ' is', ' are'), this.$toasted.error(text, {
        duration: 5000
      }), false) : true;
    }
  }
};
