import Utils from '~/utils';

export default {
  data: function() {
    return {
      notes: null,
      editMode: false,
      loading: false
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    preview: function() {
      var ref;
      return (ref = this.notes) != null ? ref.replace(/(?:\r?\n)/g, '<br>') : void 0;
    }
  },
  watch: {
    account: {
      handler: function(account) {
        return this.notes = account.notes;
      },
      immediate: true
    },
    editMode: function() {
      if (this.editMode) {
        return window.addEventListener('keyup', this.onKeyup);
      } else {
        return window.removeEventListener('keyup', this.onKeyup);
      }
    }
  },
  methods: {
    enable: function() {
      this.notes = '';
      this.account.notes = '';
      return this.switchToEditMode();
    },
    switchToEditMode: function() {
      if (window.getSelection().toString().length === 0) {
        this.editMode = true;
        return this.$nextTick(() => {
          return this.$refs.input.$refs.textarea.focus();
        });
      }
    },
    save: async function() {
      var request;
      this.loading = true;
      // trim white space and excessive line breaks
      this.notes = this.notes.trim();
      if (!this.notes.replace(/(?:\r?\n)/g, '').length) {
        this.notes = null;
      }
      request = Utils.clone(this.account);
      request.notes = this.notes;
      if ((await this.run('/accounts/update', request))) {
        this.account.notes = this.notes;
        this.editMode = false;
        this.hideNotesIfEmpty();
      }
      return this.loading = false;
    },
    cancel: function() {
      this.notes = this.account.notes;
      this.editMode = false;
      return this.hideNotesIfEmpty();
    },
    hideNotesIfEmpty: function() {
      if (!this.notes) {
        this.notes = null;
        return this.account.notes = null;
      }
    },
    onKeyup: function(e) {
      if (e.key === 'Escape') {
        return this.cancel();
      }
    }
  }
};
