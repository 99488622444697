export default {
  // config actions
  config: 'GET config',
  // user actions
  users: 'GET users?query=:query',
  user: 'GET user',
  signin: 'POST signin',
  signup: 'POST signup',
  signout: 'GET signout',
  // auth actions
  forgot: 'POST forgot',
  reset: 'POST reset',
  // accounts list actions
  accountsList: 'GET accounts/list',
  // accounts summary report
  getReport: 'GET accounts/report',
  // account actions
  accounts: 'GET accounts',
  account: 'GET accounts/:id',
  accountEvents: 'GET accounts/:id/events',
  accountContacts: 'PATCH accounts/:id/contacts',
  accountTags: 'PATCH accounts/:id/tags',
  createAccount: 'POST accounts',
  updateAccount: 'PATCH accounts/:id',
  deleteAccount: 'DELETE accounts/:id',
  // batch actions
  batches: 'GET batches',
  cleanBatch: 'PUT batches',
  importBatch: 'POST batches',
  // contact actions
  contacts: 'GET contacts?query=:query&account_id=:accountId&type=:type',
  contactsByType: 'GET contacts?query=:query&kind=:kind&account_id=:accountId',
  contact: 'GET contacts/:id',
  createContact: 'POST contacts',
  updateContact: 'PATCH contacts/:id',
  removeContact: 'DELETE contacts/:id?account_id=:accountId',
  // searching/filtering
  contactsSearch: 'GET contacts',
  contactStates: 'GET contacts/states?kind=:kind',
  // tag actions
  tags: 'GET tags?query=:query&account_id=:accountId',
  tag: 'GET tags/:id',
  createTag: 'POST tags',
  updateTag: 'PATCH tags/:id',
  removeTag: 'DELETE tags/:id?account_id=:accountId',
  // event actions
  createEvent: 'POST events',
  updateEvent: 'PATCH events/:id',
  deleteEvent: 'DELETE events/:id',
  // document actions
  document: 'GET documents/:id',
  updateDocument: 'PATCH documents/:id',
  deleteDocument: 'DELETE documents/:id',
  // transaction actions
  transactions: 'GET transactions',
  createTransaction: 'POST transactions',
  updateTransaction: 'PATCH transactions/:id',
  deleteTransaction: 'DELETE transactions/:id'
};
