import Vue from 'vue';

export default new Vue({
  data: function() {
    return {
      config: {}
    };
  },
  computed: {
    e: function() {
      return this.a * this.b;
    }
  },
  watch: {
    'c.d': function(val, old) {
      return p(`c.d changed from ${old} to ${val}`);
    }
  },
  methods: {
    load: async function() {
      var config;
      config = ((await this.$api.config())).data.config;
      // extra parsing for contact types
      config.contactTypesArray = config.contactTypes.reduce(function(acc, cur, i) {
        acc[i] = cur.key;
        return acc;
      }, []);
      config.contactIconMap = config.contactTypes.reduce(function(acc, cur) {
        acc[cur.key] = cur.val;
        return acc;
      }, {});
      return this.config = config;
    },
    jump: function(...args) {
      p(["jump", this.e, ...args]);
      return "just jumped!";
    },
    getAccount: function(id) {
      this.set('account', "Waiting for api call...");
      return setTimeout(async() => {
        return this.set('account', ((await this.$api.account(id))).data);
      }, 4000);
    }
  }
});
