export default {
  props: {
    label: String,
    type: String
  },
  computed: {
    classes: function() {
      return {
        'sidebar': this.type === 'sidebar'
      };
    }
  }
};
