var baseURL, instance;

import Axios from 'axios';

import applyConverters from 'axios-case-converter';

baseURL = process.env.VUE_APP_SERVER_URL;

instance = applyConverters(Axios.create({baseURL}));

instance.defaults.withCredentials = true;

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  var redirect;
  switch (error.response.status) {
    case 401:
      redirect = '/signin';
      if (window.location.pathname) {
        redirect += `?redirect=${encodeURIComponent(window.location.pathname)}`;
      }
      window.location.href = redirect;
      return Promise.reject(error.response.data);
    case 422:
      return Promise.reject(error.response.data);
    default:
      return Promise.reject(error.response);
  }
});

export default instance;
