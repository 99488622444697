export default {
  data: function() {
    return {
      filter: {
        hide: true
      },
      params: {
        hide: true
      },
      query: null,
      allSources: [],
      states: [],
      sources: [],
      types: this.get('/config/config/accountTypes', []),
      state: null,
      source: null,
      enabledTagIds: [],
      tagFilterMode: 'any',
      endpoint: 'accounts'
    };
  },
  computed: {
    tags: function() {
      return this.get('/accounts/tags');
    },
    page: function() {
      return this.get('/accounts/page');
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: async function(filter) {
        this.params.hide = filter.hide;
        this.params.state = filter.state !== this.states[0].id ? filter.state : null;
        this.params.source = filter.source !== this.sources[0].id ? filter.source : null;
        this.params.type = filter.type !== this.types[0] ? filter.type : null;
        this.$refs.search.clearInput(false);
        await this.run('/accounts/getList', this.params);
        this.clearEnabledTags();
        this.set('/accounts/page', 1);
        if (this.params.state !== null) {
          return this.sources = [this.sources[0]].concat(this.allSources.filter((item) => {
            return item.state === this.params.state;
          }));
        } else {
          return this.sources = this.allSources;
        }
      }
    },
    enabledTagIds: function(ids) {
      this.set('/accounts/page', 1);
      return this.run('/accounts/getList', {
        ...this.params,
        tags: ids,
        mode: this.tagFilterMode
      });
    },
    tagFilterMode: function(mode) {
      this.set('/accounts/page', 1);
      return this.run('/accounts/getList', {
        ...this.params,
        tags: this.enabledTagIds,
        mode: mode
      });
    },
    page: function(page) {
      return this.run('/accounts/getList', {
        ...this.params,
        tags: this.enabledTagIds,
        mode: this.tagFilterMode
      });
    },
    '$route.path': function(path) {
      if (path === '/accounts') {
        return this.run('/accounts/getList', {
          ...this.params,
          tags: this.enabledTagIds,
          mode: this.tagFilterMode
        });
      } else if (!path.includes('/accounts')) {
        this.filter = {
          hide: true
        };
        this.clearEnabledTags();
        return this.set('/accounts/page', 1);
      }
    }
  },
  created: async function() {
    var response;
    // get a list of states
    response = (await this.$api.contactStates({
      kind: ['Source', 'Provider']
    }));
    this.states = [
      {
        id: -1,
        name: 'All states'
      }
    ].concat(response.data);
    // get a list of sources
    response = (await this.$api.contactsByType({
      kind: 'Source'
    }));
    this.allSources = this.sources = [
      {
        id: -1,
        name: 'All sources'
      }
    ].concat(response.data);
    return (await this.run('/accounts/getList', this.params));
  },
  methods: {
    itemSelected: function(itemId) {
      if (itemId === -1) {
        this.run('/ui/toggleDialog', {
          id: 'new-account-dialog',
          open: true
        });
        return this.query = '';
      } else {
        return this.$router.push({
          name: 'viewAccount',
          params: {
            id: itemId
          }
        });
      }
    },
    toggleEnabledTag: function(tagId) {
      var index;
      index = this.enabledTagIds.indexOf(tagId);
      if (index !== -1) {
        return this.enabledTagIds.splice(index, 1);
      } else {
        return this.enabledTagIds.push(tagId);
      }
    },
    clearEnabledTags: function() {
      var i, len, ref, tag;
      if (!this.$refs.tags) {
        return;
      }
      ref = this.$refs.tags;
      for (i = 0, len = ref.length; i < len; i++) {
        tag = ref[i];
        tag.enabled = false;
      }
      return this.enabledTagIds = [];
    },
    signout: async function() {
      return (await this.run('/auth/signout'));
    }
  }
};
