export default {
  data: function() {
    return {
      query: null,
      tag: {
        color: '#DDE6E8'
      },
      selectedTagId: null,
      createMode: false
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    }
  },
  watch: {
    selectedTagId: async function(id) {
      var response;
      if (id === -1) {
        return this.createMode = true;
      } else if (id) {
        response = (await this.$api.tag(id));
        return this.tag = response.data;
      }
    },
    createMode: function() {
      if (this.createMode) {
        return this.tag.name = this.query;
      }
    }
  },
  methods: {
    addTag: async function() {
      var response, tags;
      if (this.createMode && this.$refs.createTagForm.check()) {
        if (response = (await this.$api.createTag(Object.assign(this.tag, {
          accountId: this.account.id
        })))) {
          this.tag.id = response.data.id;
          tags = this.get('/accounts/account/tags');
          tags.push(this.tag);
          this.reset();
          return true;
        }
      } else if (!this.createMode && this.$refs.addTagForm.check()) {
        if ((await this.$api.updateTag(Object.assign(this.tag, {
          accountId: this.account.id
        })))) {
          tags = this.get('/accounts/account/tags');
          tags.push(this.tag);
          this.reset();
          return true;
        }
      }
    },
    reset: function() {
      var ref;
      this.query = null;
      this.tag = {
        color: '#DDE6E8'
      };
      this.selectedTagId = null;
      this.createMode = false;
      return (ref = this.$refs.select) != null ? ref.clearInput() : void 0;
    }
  }
};
