export default {
  props: {
    contact: Object,
    creatingContact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    kinds: function() {
      return this.get('/config/config/contactTypesArray');
    }
  }
};
