import Utils from '~/utils';

import Moment from 'moment';

import AccountTags from './AccountTags';

import AccountNotes from './AccountNotes';

import AccountContacts from './AccountContacts';

import AccountPatientForm from './AccountPatientForm';

import AccountEditableField from './AccountEditableField';

export default {
  components: {AccountTags, AccountNotes, AccountContacts, AccountPatientForm, AccountEditableField},
  data: function() {
    return {
      cloned: {}
    };
  },
  computed: {
    account: function() {
      var account;
      account = this.get('/accounts/account');
      this.cloned = Utils.clone(account);
      return account;
    },
    sortedAccounts: function() {
      return this.get('/ui/accountListTable/accounts');
    },
    kinds: function() {
      return (this.get('/config/config/accountTypes', [])).slice(1);
    },
    statuses: function() {
      return this.get('/config/config/statuses');
    },
    dos: function() {
      var sorted, transactions;
      if (this.account.transactions && this.account.transactions.length) {
        transactions = this.account.transactions.filter((t) => {
          return t.charge > 0;
        });
        if (transactions.length) {
          sorted = transactions.sort((a, b) => {
            if (a.date === b.date) {
              return 1;
            }
          });
          return sorted[0].date;
        }
      }
    }
  },
  methods: {
    showAddress: function(obj) {
      return !!(['address', 'city', 'state', 'zip'].find((key) => {
        return obj[key] > "";
      }));
    },
    updateAccount: async function() {
      if (!(this.$refs.patientPopoverForm && !this.$refs.patientPopoverForm.$refs.form.check())) {
        if ((await this.run('/accounts/update', this.cloned))) {
          this.set('/accounts/account', this.cloned);
          return true;
        }
      }
    },
    openTransactionsPanel: function() {
      return this.run('/ui/openPanel', {
        id: 'transactions-panel',
        type: 'transactions'
      });
    },
    openNewEventDialog: function() {
      this.eventToBeEdited = null;
      return this.run('/ui/toggleDialog', {
        id: 'event-dialog',
        open: true
      });
    },
    goToAccount: function(direction) {
      var currentIndex, nextIndex;
      currentIndex = this.sortedAccounts.findIndex((a) => {
        return a.accountId === this.account.id;
      });
      nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
      return this.$router.push({
        name: 'viewAccount',
        params: {
          id: this.sortedAccounts[nextIndex].accountId
        }
      });
    },
    lastContactClass: function(lastContact) {
      if (Moment(lastContact).isBefore(Moment().subtract(30, 'days'))) {
        return 'older-than-1-month';
      }
    }
  }
};
