var REGEX_COLLAPSER, REGEX_NUMERIC, REGEX_PHONE;

String.prototype.repeat = function(num = 1) {
  if (num) {
    return new Array(Math.abs(num) + 1).join(this);
  } else {
    return '';
  }
};

REGEX_COLLAPSER = /^On \w{3}, \w+ \d+, \d+ at \d+:\d+ \w+ [^\n]+? wrote:((?:<br>)?\n)+>/m;

REGEX_NUMERIC = /^([+-]?)(?:(\d+)(\.\d*)?|(\.\d+))$/;

REGEX_PHONE = /^([2-9][0-8][0-9])([2-9]\d\d)(\d{4})$/;

export default {
  // increment or decrement text fields
  adjust: function(str, inc = 1) {
    var dec, dot, len, neg, num, out, pad, rgx, use, val;
    if (rgx = REGEX_NUMERIC.exec(str)) {
      // normalize supplied value
      neg = rgx[1];
      num = +rgx[2] || 0;
      dec = rgx[3] || rgx[4] || '';
      if (dec === '.') {
        dec = '.0';
      }
      val = `${neg}${num}${dec}`;
      // perform integer math
      use = +`${neg}${num}${dec.slice(1)}`;
      out = `${use + inc}`;
      // adjust sign and decimal point
      neg = out[0] === '-' ? '-' : '';
      if (neg) {
        out = out.slice(1);
      }
      dot = dec ? dec.length - 1 : 0;
      len = out.length;
      if (len <= dot) {
        pad = '0.' + (new Array(dot - len + 1)).join('0');
        out = `${pad}${out}`;
      } else if (dot) {
        out = out.slice(0, -dot) + '.' + out.slice(-dot);
      }
      if (neg) {
        out = `${neg}${out}`;
      }
      return out;
    }
  },
  // clone
  clone: function(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  // commafy an array
  commafy: function(ary, suffix, one, alt) {
    var last, size, text;
    text = (function() {
      switch (size = ary.length) {
        case 1:
          return ary[0];
        case 2:
          return ary.join(" and ");
        default:
          last = ary.pop();
          return ary.join(", ") + `, and ${last}`;
      }
    })();
    if (suffix) {
      if (one && size === 1) {
        text += one;
      }
      if (alt && size !== 1) {
        text += alt;
      }
      text += suffix;
    }
    return text;
  },
  // collapse mail partipants
  collapser: function(body) {
    var curr, deep, list, regx, solo, text;
    if ((regx = REGEX_COLLAPSER.exec(body))) {
      solo = regx.index === 0;
      text = body.slice(regx.index);
      body = body.slice(0, regx.index).trim();
      curr = deep = 0;
      list = text.split("\n").map((line) => {
        return line.replace(/^(?:> )+/, (many) => {
          curr = many.length / 2;
          line = curr === deep ? '' : "<div>".repeat(curr - deep);
          deep = curr;
          return line;
        });
      });
      if (deep > 0) {
        // assemble results
        list.push("</div>".repeat(deep));
      }
      list.unshift(`${!solo ? '\n' : ''}<div class='show-trimmed${solo ? ' show-trimmed--solo' : ''}'>•••</div><div class='trimmed${solo ? ' trimmed--solo' : ''}'>`);
      list.push("</div>");
      return body + list.join("\n");
    } else {
      return body;
    }
  },
  elide: function(list, time) {
    var b, c, j, n, o, r, ref, s, u, w;
    if (!list.length) {
      return list;
    }
    // grok
    s = 'f';
    o = {
      f: [list[0].id, 0, 0]
    };
    for (c = j = 1, ref = list.length; j < ref; c = j += 1) {
      n = list[c];
      b = [n.id, c, c];
      u = n.sent_at > time;
      switch (s) {
        case 'f':
          if (n.id === o.f[0]) {
            o.f[2] = c;
          } else {
            o[s = !u ? 'd' : 'u'] = b;
          }
          break;
        case 'd':
          if (n.id === o.d[0]) {
            o.d[2] = c;
            if (u) {
              o[s = 'u'] = o.d;
              delete o.d;
            }
          } else {
            o[s = !u ? 'l' : 'u'] = b;
          }
          break;
        case 'u':
          if (n.id === o.u[0]) {
            o.u[2] = c;
          } else {
            o[s = 'l'] = b;
          }
          break;
        case 'l':
          if (!o.u) {
            if (u) {
              o.u = b;
            }
          } else if (o.u[2] === c - 1 && n.id === o.u[0]) {
            o.u[2] = c;
          }
          if (n.id === o.l[0]) {
            o.l[2] = c;
          } else {
            o.l = b;
          }
      }
    }
    // result
    r = [list[o.f[2]]];
    if (o.l) {
      if (o.u && o.u[2] === o.l[2]) {
        delete o.u;
      }
    } else if (o.u && o.d) {
      o.l = o.u;
      delete o.u;
    }
    if (w = o.u || o.d) {
      r.push(w[1] - o.f[2] === 1 ? ', ' : ' .. ');
      r.push(list[w[2]]);
      if (o.l) {
        if (!(!o.u && o.l[1] - o.d[2] === 1 && o.l[0] === o.f[0])) {
          r.push(o.l[1] - w[2] === 1 ? ', ' : ' .. ');
          r.push(list[o.l[2]]);
        }
      }
    }
    return r;
  },
  // format a domestic phone
  phone: function(str) {
    var ext, num, rgx;
    if (!str) {
      return "";
    }
    // pluck off extension, tries several variants
    [num, ...ext] = str.split(/\s*((?:ext?\.?|x|#|:|,)\s*)+/i);
    if (ext = ext.pop()) {
      ext = ext.replace(/\D+/g, '');
    }
    // adjust base number, allow only domestic phones
    num = num.replace(/^[^2-9]*/, '').replace(/\D+/g, '');
    // assemble final number
    if (rgx = REGEX_PHONE.exec(num)) {
      num = `(${rgx[1]}) ${rgx[2]}-${rgx[3]}`;
      if (num && ext) {
        num += `, ext. ${ext}`;
      }
      return num;
    } else {
      return str = false;
    }
  },
  // quick-n-dirty string hash: see https://bit.ly/2pWWfOf
  strhash: function(str) {
    var hash, i;
    i = str.length;
    hash = 5381;
    while (i) {
      hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    return hash >>> 0;
  },
  // calculate foreground color based on background color for sufficient contrast
  // based on https://24ways.org/2010/calculating-color-contrast
  foregroundColor: function(backgroundColor) {
    var b, color, g, r, yiq;
    color = backgroundColor.replace('#', '');
    r = parseInt(color.substr(0, 2), 16);
    g = parseInt(color.substr(2, 2), 16);
    b = parseInt(color.substr(4, 2), 16);
    yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    if (yiq >= 128) {
      return '#2b2b2b';
    } else {
      return '#ffffff';
    }
  }
};
