import Utils from '~/utils';

import Moment from 'moment';

export default {
  props: {
    panelId: String
  },
  data: function() {
    return {
      transactions: []
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    iconMap: function() {
      return {
        key: 'kind',
        map: this.get('/config/config/contactIconMap')
      };
    }
  },
  watch: {
    account: {
      immediate: true,
      handler: function(account) {
        if (!account.transactions) {
          return;
        }
        this.transactions = Utils.clone(account.transactions);
        this.addEmptyRow();
        this.sortTransactions();
        return this.computeBalances();
      }
    }
  },
  methods: {
    computeBalances: function() {
      var bal, pos, results, row;
      bal = 0;
      pos = this.transactions.length;
      results = [];
      while (--pos >= 0) {
        row = this.transactions[pos];
        if (row.charge) {
          bal += row.charge;
        }
        if (row.payment > 0) {
          bal -= row.payment;
        }
        if (row.writeoff) {
          bal -= row.writeoff;
        }
        results.push(row.balance = Math.round(bal * 100) / 100);
      }
      return results;
    },
    save: async function(transaction, index) {
      var response;
      if (transaction.id) {
        if (this.$refs[`form-${index}`][0].check() && this.extraValidation(transaction)) {
          if (response = (await this.$api.updateTransaction(transaction))) {
            this.$set(this.account, 'balance', response.data.accountBalance);
            transaction.fromName = response.data.fromName;
            this.$set(this.account.transactions, index - 1, Utils.clone(transaction));
            this.$set(transaction, 'edit', false);
            this.sortTransactions();
            return this.computeBalances();
          }
        }
      } else {
        if (this.$refs[`form-${index}`][0].check() && this.extraValidation(transaction)) {
          transaction.accountId = this.account.id;
          if (response = (await this.$api.createTransaction(transaction))) {
            this.$set(this.account, 'balance', response.data.accountBalance);
            transaction.id = response.data.id;
            transaction.fromName = response.data.fromName;
            transaction.edit = false;
            this.account.transactions.unshift(Utils.clone(transaction));
            this.addEmptyRow();
            this.sortTransactions();
            return this.computeBalances();
          }
        }
      }
    },
    cancel: function(transaction, index) {
      var originalTransaction;
      originalTransaction = this.account.transactions.find(function(t) {
        return t.id === transaction.id;
      });
      originalTransaction.edit = false;
      this.$set(this.transactions, index, Utils.clone(originalTransaction));
      return this.computeBalances();
    },
    remove: async function(transaction, index) {
      var response;
      if (response = (await this.$api.deleteTransaction(transaction))) {
        this.$set(this.account, 'balance', response.data.accountBalance);
        this.transactions.splice(index, 1);
        this.account.transactions.splice(index - 1, 1);
        return this.computeBalances();
      }
    },
    setFromId: function(transaction, contactId) {
      return transaction.fromId = contactId;
    },
    addEmptyRow: function() {
      this.transactions.unshift({});
      return this.$nextTick(function() {
        return this.$set(this.transactions, 0, {
          edit: true,
          date: Moment().format('YYYY-MM-DD'),
          fromName: ''
        });
      });
    },
    extraValidation: function(transaction) {
      var error;
      if (!transaction.charge && !transaction.payment) {
        error = 'Enter a charge or a payment';
      } else if (transaction.charge && (transaction.payment || transaction.writeoff)) {
        error = 'When entering an invoice amount, payment and reduction should be left empty';
      }
      if (error) {
        this.$toasted.error(error, {
          duration: 5000
        });
        return false;
      } else {
        return true;
      }
    },
    sortTransactions: function() {
      var emptyRow, sorted;
      emptyRow = this.transactions.shift();
      sorted = this.transactions.sort((a, b) => {
        if (a.date === b.date) {
          return 1;
        } else if (a.date > b.date) {
          return -1;
        }
      });
      sorted.unshift(emptyRow);
      return this.transactions = sorted;
    },
    writeOffRemainder: function(transaction) {
      var currentIndex, previousBalance, ref;
      currentIndex = this.transactions.findIndex((t) => {
        return t.id === transaction.id;
      });
      previousBalance = (ref = this.transactions[currentIndex + 1]) != null ? ref.balance : void 0;
      if (!previousBalance) {
        return this.$toasted.error('No previous balance found', {
          duration: 5000
        });
      } else {
        return transaction.writeoff = previousBalance - transaction.payment;
      }
    }
  }
};
