import {
  jsPanel
} from 'jspanel4';

export default {
  props: {
    id: String,
    headerTitle: String,
    initialStatus: String
  },
  data: function() {
    return {
      panel: null
    };
  },
  created: function() {
    jsPanel.autopositionSpacing = 6;
    return $state.$on('closePanels', () => {
      return this.panel.close();
    });
  },
  mounted: function() {
    var self;
    if (this.id) {
      self = this;
      return this.panel = jsPanel.create({
        borderRadius: '4px',
        closeOnEscape: true,
        content: document.querySelector(`#${self.id}`),
        contentSize: '65rem 55rem',
        dragit: {
          containment: 0,
          opacity: 1
        },
        headerTitle: this.headerTitle,
        iconfont: 'far',
        maximizedMargin: 30,
        position: {
          fixed: true
        },
        onwindowresize: true,
        resizeit: {
          containment: 0,
          minWidth: 463,
          minHeight: 490
        },
        theme: 'none',
        setStatus: this.initialStatus,
        onstatuschange: function(panel) {
          var backdrop;
          if (this.status === 'maximized') {
            backdrop = document.createElement('div');
            backdrop.id = `jsPanel-backdrop-${self.id}`;
            backdrop.className = 'jsPanel-backdrop';
            document.body.appendChild(backdrop);
            return panel.resizeit('disable');
          } else {
            document.querySelector(`#jsPanel-backdrop-${self.id}`).remove();
            return panel.resizeit('enable');
          }
        },
        onbeforeclose: function() {
          self.run('/ui/closePanel', self.id);
          if (document.querySelector(`#jsPanel-backdrop-${self.id}`)) {
            document.querySelector(`#jsPanel-backdrop-${self.id}`).remove();
          }
          return true;
        }
      });
    }
  },
  beforeDestroy: function() {
    return $state.$off('closePanels');
  }
};
