import Utils from '~utils';

export default {
  props: {
    id: Number,
    name: String,
    color: {
      type: String,
      default: '#e8e8e8'
    },
    toggleable: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      enabled: false
    };
  },
  computed: {
    classes: function() {
      return {
        toggleable: this.toggleable,
        enabled: this.enabled,
        disabled: !this.enabled
      };
    },
    styles: function() {
      return {
        backgroundColor: this.color,
        color: Utils.foregroundColor(this.color)
      };
    },
    trimmedName: function(max = 8) {
      if (this.name.length > max) {
        return this.name.slice(0, max).trim() + '…';
      } else {
        return this.name;
      }
    }
  },
  methods: {
    onClick: function() {
      if (!this.toggleable) {
        return;
      }
      this.enabled = !this.enabled;
      return this.$emit('toggle:tag', this.id);
    }
  }
};
