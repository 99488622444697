export default {
  name: "Dashboard",
  methods: {
    getReport: async function() {
      var resp;
      resp = ((await this.$api.getReport())).data;
      return window.open(resp.link, "_blank");
    },
    openImporter: function() {
      return this.run('/ui/openPanel', {
        id: 'importer-panel',
        type: 'importer'
      });
    }
  }
};
