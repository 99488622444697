export default {
  props: {
    input: String,
    options: Array,
    groupLabel: String,
    groupValues: String,
    labelKey: String,
    detailsKey: String,
    iconMap: Object,
    selectedItemId: Number,
    endpoint: String,
    params: Object,
    label: String,
    hideLabel: Boolean,
    placeholder: String,
    showSuggestedItems: {
      type: Boolean,
      default: true
    },
    clearAfterSelection: {
      type: Boolean,
      default: false
    },
    addLabel: String,
    addIfNoItems: {
      type: Boolean,
      default: true
    },
    inverted: {
      type: Boolean,
      default: false
    },
    required: Boolean
  },
  data: function() {
    return {
      model: this.input,
      items: [],
      current: -1,
      selected: -1,
      valid: true
    };
  },
  computed: {
    classes: function() {
      return {
        'required': this.required,
        'error': !this.valid,
        'inverted': this.inverted,
        'has-items': this.items.length
      };
    }
  },
  watch: {
    input: {
      immediate: true,
      handler: function(input) {
        var items;
        this.model = input;
        if (this.options && this.groupLabel) {
          items = this.itemsFromGroupedOptions();
          return this.selected = items.findIndex((e) => {
            return e[this.labelKey] === input;
          });
        } else if (this.options && this.labelKey) {
          return this.selected = this.options.findIndex((e) => {
            return e[this.labelKey] === input;
          });
        } else if (this.options) {
          return this.selected = this.options.findIndex((e) => {
            return e === input;
          });
        }
      }
    }
  },
  methods: {
    update: async function() {
      var items, ref, response;
      if (this.options && this.groupLabel) {
        if (this.model.length === 0) {
          this.items = this.itemsFromGroupedOptions();
          return;
        }
        items = this.itemsFromGroupedOptions();
        this.items = items.filter((e) => {
          var searchRegex;
          if (e.heading) {
            return false;
          }
          searchRegex = new RegExp(this.model, 'i');
          return searchRegex.test(e[this.labelKey]);
        });
      } else if (this.options) {
        this.items = this.options.filter((e) => {
          var searchRegex;
          searchRegex = new RegExp(this.model, 'i');
          if (this.labelKey) {
            return searchRegex.test(e[this.labelKey]);
          } else {
            return searchRegex.test(e);
          }
        });
      } else if (this.endpoint) {
        if ((ref = this.model) != null ? ref.length : void 0) {
          response = (await this.$api[this.endpoint]({
            ...this.params,
            query: this.model
          }));
          this.items = response.data;
          if (this.addIfNoItems) {
            if (!(this.items.length && !this.items[this.items.length - 1].more)) {
              this.items.push({
                add: true
              });
            }
          }
        } else {
          this.items = [];
          return;
        }
      }
      if (this.items.length) {
        return this.current = 0;
      }
    },
    suggestedItems: async function() {
      var ref, response;
      if (!this.showSuggestedItems) {
        return;
      }
      this.$refs.input.focus();
      if (this.options && this.groupLabel) {
        return this.items = this.itemsFromGroupedOptions();
      } else if (this.options) {
        return this.items = this.options;
      } else if (this.endpoint) {
        response = (await this.$api[this.endpoint]({
          ...this.params,
          query: ''
        }));
        this.items = response.data;
        if (this.addIfNoItems && !((ref = this.items.slice(-1)[0]) != null ? ref.more : void 0)) {
          return this.items.push({
            add: true
          });
        }
      }
    },
    itemsFromGroupedOptions: function() {
      var group, i, items, j, len, len1, ref, ref1, value;
      items = [];
      ref = this.options;
      for (i = 0, len = ref.length; i < len; i++) {
        group = ref[i];
        items.push({
          label: group[this.groupLabel],
          heading: true
        });
        ref1 = group[this.groupValues];
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          value = ref1[j];
          items.push({
            label: value
          });
        }
      }
      return items;
    },
    itemClasses: function(index) {
      return {
        'active': this.current === index && !this.items[index].more,
        'refine': this.items[index].more,
        'selected': this.selected === index && !this.endpoint,
        'heading': this.items[index].heading
      };
    },
    onFocus: function() {
      return this.valid = true;
    },
    onClick: function() {
      if (this.items.length) {
        return this.clearItems();
      } else {
        return this.suggestedItems();
      }
    },
    hit: function() {
      var item;
      item = this.items[this.current];
      if (this.current === -1 || item.heading || item.more) {
        return;
      }
      this.selected = this.current;
      if (item.add) {
        this.$emit('update:input', this.model);
        this.$emit('update:selectedItemId', -1);
      } else if (item.id) {
        this.$emit('update:selectedItemId', item.id);
        this.model = item[this.labelKey];
      } else if (item[this.labelKey]) {
        this.$emit('update:input', item[this.labelKey]);
        this.model = item[this.labelKey];
      } else {
        this.$emit('update:input', item);
        this.model = item;
      }
      this.clearItems();
      if (this.clearAfterSelection) {
        return this.clearInput(false);
      }
    },
    up: function() {
      var ref;
      if (this.current > 0) {
        this.current--;
      } else if (this.current === -1) {
        this.current = this.items.length - 1;
      } else {
        this.current = -1;
      }
      if ((ref = this.items[this.current]) != null ? ref.heading : void 0) {
        return this.up();
      }
    },
    down: function() {
      var ref;
      if (this.current < this.items.length - 1) {
        this.current++;
      } else {
        this.current = -1;
      }
      if ((ref = this.items[this.current]) != null ? ref.heading : void 0) {
        return this.down();
      }
    },
    clearItems: function() {
      return this.items = [];
    },
    clearInput: function(focus = true) {
      this.clearItems();
      if (this.model === '') {
        this.$refs.input.blur();
        return;
      }
      this.model = '';
      this.selected = -1;
      if (focus) {
        this.$refs.input.focus();
      } else {
        this.$refs.input.blur();
      }
      if (this.options) {
        return this.$nextTick(() => {
          return this.suggestedItems();
        });
      }
    },
    check: function() {
      var ref;
      if (!((ref = this.model) != null ? ref.length : void 0)) {
        return this.valid = !this.required;
      }
    }
  }
};
