import SidebarNav from './SidebarNav';

import {
  MDCDrawer
} from '@material/drawer';

export default {
  components: {SidebarNav},
  data: function() {
    return {
      drawer: null
    };
  },
  computed: {
    open: function() {
      return this.get('/ui/drawer');
    }
  },
  watch: {
    open: function(newValue) {
      return this.drawer.open = !!newValue;
    }
  },
  mounted: function() {
    this.drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
    return this.drawer.listen('MDCDrawer:closed', () => {
      return this.set('/ui/drawer', false);
    });
  }
};
