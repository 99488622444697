import Vue from 'vue';

export default new Vue({
  data: function() {
    return {
      user: {},
      wait: null
    };
  },
  methods: {
    signin: async function(credentials) {
      try {
        this.wait = 'signingIn';
        return this.user = ((await this.$api.signin(credentials))).data;
      } finally {
        this.wait = null;
      }
    },
    signup: async function(user) {
      try {
        this.wait = 'signingUp';
        return this.user = ((await this.$api.signin(credentials))).data;
      } finally {
        this.wait = null;
      }
    },
    signout: async function() {
      await this.$api.signout();
      return this.user = {};
    },
    forgot: async function(forgotEmail) {
      try {
        this.wait = 'sendingReset';
        return (await this.$api.forgot({
          email: forgotEmail
        }));
      } finally {
        this.wait = null;
      }
    }
  }
});
