export default {
  props: {
    account: Object,
    creatingAccount: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      sources: []
    };
  },
  computed: {
    kinds: function() {
      return (this.get('/config/config/accountTypes', [])).slice(1);
    }
  },
  watch: {
    'account.source': function(source) {
      if (source) {
        return this.account.sourceId = source.id;
      }
    }
  },
  created: async function() {
    var response;
    response = (await this.$api.contactsByType({
      kind: 'Source'
    }));
    return this.sources = response.data;
  },
  mounted: function() {
    var body;
    if (this.$refs.deleteConfirmation) {
      // needed to move delete confirmation dialog outside of popover
      body = document.querySelector('body');
      return body.appendChild(this.$refs.deleteConfirmation.$el);
    }
  },
  methods: {
    deleteAccount: async function() {
      if ((await this.$api.deleteAccount({
        id: this.account.id
      }))) {
        this.$router.replace('/accounts');
        return this.$toasted.show('Account deleted');
      }
    },
    deleteAccountConfirmation: function() {
      return this.run('/ui/toggleDialog', {
        id: 'delete-confirmation-dialog',
        open: true
      });
    }
  }
};
