import Moment from 'moment';

export default {
  props: {
    input: String,
    label: String
  },
  data: function() {
    return {
      time: '',
      period: 'am'
    };
  },
  watch: {
    '$data': {
      handler: function() {
        return this.$emit('update:input', this.time + this.period);
      },
      deep: true
    },
    time: function(val, old) {
      if (val.length === 1 && +val > 1) {
        return this.time = '0' + val;
      }
    }
  },
  created: function() {
    var time;
    if (this.input != null) {
      time = Moment(this.input, 'hh:mma');
      this.time = time.format('hh:mm');
      return this.period = time.format('a');
    }
  }
};
