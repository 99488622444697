var State, VueState, kind, num, regx, vue, walk;

import Vue from 'vue';

num = function(val) {
  return /^-?(\d+)$/.test(val);
};

kind = function(obj) {
  var ref;
  if (obj != null) {
    return ((ref = obj.constructor) != null ? ref.name : void 0) || Object.prototype.toString.call(obj).slice(8, -1);
  } else {
    return String(obj);
  }
};

regx = /([.\/][^.\/\[\<\s]+|\[[-+]?\d+\]|\[(?:"[^"]+"|'[^']+')\])/;

walk = function(path) {
  var chr, i, len, list, part, results;
  list = ('.' + path).split(regx);
  list.shift();
  results = [];
  for (i = 0, len = list.length; i < len; i += 2) {
    part = list[i];
    switch (chr = part[0]) {
      case '.':
      case '/':
        results.push(part.slice(1));
        break;
      case '[':
        if (part[1] === '"' || part[1] === "'") {
          results.push(part.slice(2, -2));
        } else {
          results.push(+(part.slice(1, -1)));
        }
        break;
      default:
        continue;
    }
  }
  return results;
};

vue = function(state) {
  var computed, k, ref, v;
  computed = {};
  ref = state.$options.computed;
  for (k in ref) {
    v = ref[k];
    computed[k] = v.call(state);
  }
  return Object.assign({}, state.$props, state.$data, computed);
};

State = {
  get: function(state, path, valu) {
    var cls, i, len, list, name, nest, out, prop, ref;
    list = walk(path);
    for (i = 0, len = list.length; i < len; i++) {
      prop = list[i];
      if (!((state != null) && typeof state === 'object')) {
        return valu;
      }
      if (num(prop) && Array.isArray(state) && +prop < 0) {
        prop = state.length + prop;
      }
      state = state[prop];
    }
    // if state? then state else valu # non-Vue version
    if ((state != null) && (cls = kind(state))) {
      if (cls === 'Vue' || cls === 'VueComponent') {
        if (path === '/') {
          out = {};
          ref = vue(state);
          for (name in ref) {
            nest = ref[name];
            cls = kind(nest);
            out[name] = cls === 'Vue' || cls === 'VueComponent' ? vue(nest) : nest;
          }
          return out;
        } else {
          return vue(state);
        }
      } else {
        return state;
      }
    } else {
      return valu;
    }
  },
  set: function(state, path, valu) {
    var i, last, len, list, next, prop, slot;
    state = state.$data; // Vue-specific, only $data is settable
    list = walk(path);
    last = list.length - 1;
    for (slot = i = 0, len = list.length; i < len; slot = ++i) {
      prop = list[slot];
      if (slot === last) {
        next = valu;
      } else {
        if (num(prop) && Array.isArray(state) && +prop < 0) {
          prop = state.length + prop;
        }
        next = state.hasOwnProperty(prop) ? state[prop] : void 0;
        if (next == null) {
          next = void 0;
        }
        if (typeof next !== 'object') {
          next = (num(list[slot + 1]) ? [] : {});
        }
      }
      // state = state[prop] = next # non-Vue version
      state = Vue.set(state, prop, next);
    }
    return state;
  },
  inc: function(state, path, step = 1, init = 0) {
    var i, last, len, list, next, prop, slot;
    list = walk(path);
    last = list.length - 1;
    for (slot = i = 0, len = list.length; i < len; slot = ++i) {
      prop = list[slot];
      if (slot === last) {
        if (state.hasOwnProperty(prop)) {
          next = state[prop];
        }
        next = num(next) ? next + step : init;
      } else {
        if (num(prop) && Array.isArray(state) && +prop < 0) {
          prop = state.length + prop;
        }
        next = state.hasOwnProperty(prop) ? state[prop] : void 0;
        if (next == null) {
          next = void 0;
        }
        if (typeof next !== 'object') {
          next = (num(list[slot + 1]) ? [] : {});
        }
      }
      // state = state[prop] = next # non-Vue version
      state = Vue.set(state, prop, next);
    }
    return state;
  },
  run: function(state, path, ...args) {
    var fn;
    fn = typeof path === 'function' ? path : this.get(state, path); //!# FIXME: redundant
    if (typeof fn === 'function') {
      return fn.call(state, ...args);
    }
    console.warn(`undefined function ${path}`);
  }
};

export default VueState = {
  install: function(Vue, options) {
    var fn, state;
    state = (Vue.observable({
      '/': (options != null ? options.state : void 0) || {}
    }))['/'];
    for (fn in State) {
      (function(fn) {
        return state[fn] = Vue.prototype[fn] = function(...args) {
          var i, ref, ref1, size, start;
          start = args[0][0] === '/' ? state : this;
          if (size = (ref = /^((?:\.\.\/)+)/g.exec(args[0])) != null ? ref[1].length : void 0) {
            for (i = 1, ref1 = size / 3; i <= ref1; i += 1) {
              start = start.$parent;
            }
          }
          return State[fn](start, ...args);
        };
      })(fn);
    }
    if (options != null ? options.global : void 0) {
      return window[options.global] = state;
    }
  }
};

// if options.devtools
//   data = {}
//   data[k] = v._data for k, v of $state._data
//   data
