import Moment from 'moment';

import VueDatepicker from 'vuejs-datepicker';

export default {
  components: {VueDatepicker},
  props: {
    input: String,
    label: String,
    hideLabel: Boolean,
    alignRight: {
      type: Boolean,
      default: false
    },
    required: Boolean
  },
  data: function() {
    return {
      model: this.input,
      valid: true
    };
  },
  watch: {
    input: function(input) {
      return this.model = input;
    },
    model: function(model) {
      return this.$emit('update:input', Moment.utc(model).format('YYYY-MM-DD'));
    }
  },
  mounted: function() {
    return this.$refs.input.$el.addEventListener('click', (e) => {
      return this.positionCalendar();
    });
  },
  methods: {
    onOpened: function() {},
    // opened event broken since 1.5.4: https://github.com/charliekassel/vuejs-datepicker/issues/625
    // TODO: should call positionCalendar() here
    onIconClick: function() {
      var input;
      input = this.$refs.input.$el.querySelector('input');
      input.click();
      return input.focus();
    },
    positionCalendar: function() {
      var calendarEls, calendarWidth, i, inputEl, j, k, len, len1, len2, node, results, results1, results2;
      calendarWidth = 265;
      inputEl = this.$refs.input.$el;
      calendarEls = inputEl.querySelectorAll('.vdp-datepicker__calendar');
      if (this.alignRight) {
        results = [];
        for (i = 0, len = calendarEls.length; i < len; i++) {
          node = calendarEls[i];
          results.push(node.classList.add('align-right'));
        }
        return results;
      } else {
        if (inputEl.getBoundingClientRect().left > window.innerWidth - calendarWidth - 20) {
          results1 = [];
          for (j = 0, len1 = calendarEls.length; j < len1; j++) {
            node = calendarEls[j];
            results1.push(node.classList.add('align-right'));
          }
          return results1;
        } else {
          results2 = [];
          for (k = 0, len2 = calendarEls.length; k < len2; k++) {
            node = calendarEls[k];
            results2.push(node.classList.remove('align-right'));
          }
          return results2;
        }
      }
    },
    check: function(form) {
      var ref;
      return this.valid = !(form && this.required && (!((ref = this.model) != null ? ref.toString().length : void 0) || this.model === 'Invalid date'));
    }
  }
};
