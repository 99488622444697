import Utils from '~/utils';

export default {
  props: {
    documentId: Number,
    panelId: String
  },
  data: function() {
    return {
      document: {},
      clonedTitle: null
    };
  },
  computed: {
    documents: function() {
      return this.get('/accounts/account/documents');
    }
  },
  created: async function() {
    var response;
    response = (await this.$api.document(this.documentId));
    this.document = response.data;
    return window.addEventListener('keyup', this.onKeyup);
  },
  beforeDestroy: function() {
    return window.removeEventListener('keyup', this.onKeyup);
  },
  methods: {
    onKeyup: function(e) {
      var all, cur, i, len, top;
      all = document.querySelectorAll('.jsPanel');
      for (i = 0, len = all.length; i < len; i++) {
        cur = all[i];
        if (!top || +cur.style.zIndex > +top.style.zIndex) {
          top = cur;
        }
      }
      if (this.panelId === (top != null ? top.querySelector('.jsPanel-content').firstChild.id : void 0)) {
        switch (e.key) {
          case 'ArrowRight':
            return this.nextDocument();
          case 'ArrowLeft':
            return this.prevDocument();
        }
      }
    },
    nextDocument: async function() {
      var response;
      response = (await this.$api.document(this.document.nextDocumentId));
      return this.document = response.data;
    },
    prevDocument: async function() {
      var response;
      response = (await this.$api.document(this.document.prevDocumentId));
      return this.document = response.data;
    },
    saveDocument: async function() {
      var document, index;
      document = Utils.clone(this.document);
      document.title = this.clonedTitle;
      if ((await this.$api.updateDocument(document))) {
        this.document.title = document.title;
        index = this.documents.findIndex((e) => {
          return e.id === document.id;
        });
        this.$set(this.documents, index, document);
        return this.clonedTitle = null;
      }
    },
    deleteDocument: async function() {
      var index;
      if ((await this.$api.deleteDocument({
        id: this.documentId
      }))) {
        index = this.documents.findIndex((e) => {
          return e.id === this.documentId;
        });
        this.documents.splice(index, 1);
        this.$refs.panel.panel.close();
        return this.$toasted.show('Document deleted');
      }
    },
    deleteDocumentConfirmation: function() {
      return this.run('/ui/toggleDialog', {
        id: 'delete-confirmation-dialog',
        open: true
      });
    }
  }
};
