var api;

import Vue from 'vue';

import axios from './axios';

import actions from './actions';

import {
  ApiGroup
} from 'axios-actions';

api = new ApiGroup(axios, actions);

Vue.prototype.$api = api;

export default api;
