import AccountContactForm from './AccountContactForm';

export default {
  components: {AccountContactForm},
  data: function() {
    return {
      query: null,
      contact: {},
      selectedContactId: null,
      createMode: false
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    iconMap: function() {
      return {
        key: 'kind',
        map: this.get('/config/config/contactIconMap')
      };
    }
  },
  watch: {
    selectedContactId: async function(id) {
      var response;
      if (id === -1) {
        return this.createMode = true;
      } else if (id) {
        response = (await this.$api.contact(id));
        return this.contact = response.data;
      }
    },
    createMode: function() {
      if (this.createMode) {
        return this.contact.name = this.query;
      }
    }
  },
  methods: {
    addContact: async function() {
      var response;
      if (this.createMode && this.$refs.createContactForm.$refs.form.check()) {
        if (response = (await this.$api.createContact(Object.assign(this.contact, {
          accountId: this.account.id
        })))) {
          this.contact.id = response.data.id;
          this.updateContactList(this.contact);
          return true;
        }
      } else if (!this.createMode && this.$refs.addContactForm.check()) {
        if ((await this.$api.updateContact(Object.assign(this.contact, {
          accountId: this.account.id
        })))) {
          this.updateContactList(this.contact);
          return true;
        }
      }
    },
    updateContactList: function(contact) {
      var contacts;
      contacts = this.get('/accounts/account/contacts');
      contacts.push(contact);
      return this.reset();
    },
    reset: function() {
      var ref;
      this.query = null;
      this.contact = {};
      this.selectedContactId = null;
      this.createMode = false;
      return (ref = this.$refs.select) != null ? ref.clearInput() : void 0;
    }
  }
};
