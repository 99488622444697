export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    label: String,
    icon: String,
    iconPosition: String,
    iconWeight: {
      type: String,
      default: 'solid'
    },
    iconSize: String,
    size: String,
    active: Boolean,
    success: Boolean,
    disabled: Boolean,
    loading: Boolean,
    boxShadow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes: function() {
      return {
        'default': this.type === 'default',
        'primary': this.type === 'primary' || this.type === 'submit',
        'secondary': this.type === 'secondary',
        'header': this.type === 'header',
        'dialog': this.type === 'dialog',
        'sidebar': this.type === 'sidebar',
        'icon': this.icon,
        'icon-only': this.icon && !this.label,
        'icon-left': this.iconPosition === 'left',
        'icon-right': this.iconPosition === 'right',
        'size-small': this.size === 'small',
        'size-large': this.size === 'large',
        'size-full-width': this.size === 'fullWidth',
        'active': this.active,
        'success': this.success,
        'no-shadow': !this.boxShadow,
        'loading': this.loading
      };
    }
  }
};
