export default {
  props: {
    query: String,
    items: Array,
    itemPrefix: String,
    highlightedItem: Object,
    placeholder: String,
    size: String,
    outline: Boolean,
    darkTheme: Boolean
  },
  data: function() {
    return {
      model: this.query
    };
  },
  watch: {
    query: function(query) {
      return this.model = query;
    },
    model: function(model) {
      return this.$emit('update:query', model);
    },
    items: function(items) {
      if (this.model) {
        return this.highlightFirstItem();
      }
    }
  },
  // created: -> @highlightFirstItem()
  methods: {
    selectHighlightedItem: function() {
      if (!this.highlightedItem) {
        return;
      }
      this.$emit('highlightedItemSelected', this.highlightedItem);
      return this.model = '';
    },
    changeHighlightedItem: function(direction) {
      var currentIndex;
      if (!this.items) {
        return;
      }
      currentIndex = this.items.findIndex((i) => {
        return i === this.highlightedItem;
      });
      if (direction === 'up' && currentIndex > 0) {
        this.$emit('update:highlightedItem', this.items[currentIndex - 1]);
      } else if (direction === 'down' && currentIndex < (this.items.length - 1)) {
        this.$emit('update:highlightedItem', this.items[currentIndex + 1]);
      }
      if (this.highlightedItem) {
        return this.$nextTick(() => {
          var el;
          el = document.querySelector(`#${this.itemPrefix}-${this.highlightedItem.id}`);
          return el.scrollIntoView({
            block: 'nearest'
          });
        });
      }
    },
    highlightFirstItem: function() {
      var ref;
      return this.$emit('updated:highlightedItem', ((ref = this.items) != null ? ref.length : void 0) ? this.items[0] : null);
    }
  }
};
