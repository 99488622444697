import Tippy from 'tippy.js';

export default {
  props: {
    content: String
  },
  mounted: function() {
    return Tippy(this.$refs.trigger, {
      a11y: false,
      content: this.$refs.content,
      duration: [200, 150],
      maxWidth: 300,
      theme: 'tooltip'
    });
  }
};
