import GoogleMapsApi from 'load-google-maps-api';

export default {
  props: {
    address: String,
    city: String,
    state: String,
    zip: String,
    legend: String,
    disabled: Boolean
  },
  computed: {
    _address: {
      get: function() {
        return this.address;
      },
      set: function(address) {
        return this.$emit('update:address', address);
      }
    },
    _city: {
      get: function() {
        return this.city;
      },
      set: function(city) {
        return this.$emit('update:city', city);
      }
    },
    _state: {
      get: function() {
        return this.state;
      },
      set: function(state) {
        return this.$emit('update:state', state);
      }
    },
    _zip: {
      get: function() {
        return this.zip;
      },
      set: function(zip) {
        return this.$emit('update:zip', zip);
      }
    }
  },
  mounted: async function() {
    var autocomplete, options;
    await GoogleMapsApi({
      key: 'AIzaSyATMUtgy7ldqevO2-c2lQcKgwa2bBCxFUQ',
      libraries: ['places']
    });
    options = {
      types: ['address'],
      componentRestrictions: {
        country: 'us'
      }
    };
    autocomplete = new google.maps.places.Autocomplete(this.$refs.address, options);
    autocomplete.setFields(['address_components']);
    return autocomplete.addListener('place_changed', () => {
      var address, component, i, len, place, ref;
      place = autocomplete.getPlace();
      if (place.hasOwnProperty('address_components')) {
        address = [];
        ref = place.address_components;
        for (i = 0, len = ref.length; i < len; i++) {
          component = ref[i];
          switch (component.types[0]) {
            case 'street_number':
              address.push(component.long_name);
              break;
            case 'route':
              address.push(component.long_name);
              break;
            case 'locality':
              this.$emit('update:city', component.long_name);
              break;
            case 'administrative_area_level_1':
              this.$emit('update:state', component.short_name);
              break;
            case 'postal_code':
              this.$emit('update:zip', component.short_name);
          }
        }
        return this.$emit('update:address', address.join(' '));
      }
    });
  },
  methods: {
    nextField: function() {
      return this.$refs.city.$refs.input.focus();
    }
  }
};
