import Utils from '~utils';

import Swatches from 'vue-swatches';

import 'vue-swatches/dist/vue-swatches.min.css';

export default {
  components: {Swatches},
  computed: {
    triggerStyles: function() {
      return {
        backgroundColor: this.model,
        color: Utils.foregroundColor(this.model)
      };
    },
    valid: function() {
      return true;
    }
  },
  props: {
    input: String,
    label: String
  },
  data: function() {
    return {
      model: this.input
    };
  },
  watch: {
    input: function(input) {
      return this.model = input;
    },
    model: function(model) {
      return this.$emit('update:input', model);
    }
  }
};
