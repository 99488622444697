export default {
  props: {
    context: String
  },
  computed: {
    classes: function() {
      return {
        'global': this.context === 'global',
        'panel': this.context === 'contentPanel'
      };
    }
  }
};
