import Importer from '~/components/dashboard/Importer';

import AccountTransactions from '~/components/accounts/AccountTransactions';

import AccountDocumentDetails from '~/components/accounts/AccountDocumentDetails';

export default {
  components: {Importer, AccountTransactions, AccountDocumentDetails},
  computed: {
    importerPanels: function() {
      return (this.get('/ui/panels')).filter(function(p) {
        return p.type === 'importer';
      });
    },
    transactionPanels: function() {
      return (this.get('/ui/panels')).filter(function(p) {
        return p.type === 'transactions';
      });
    },
    documentDetailPanels: function() {
      return (this.get('/ui/panels')).filter(function(p) {
        return p.type === 'documentDetails';
      });
    }
  }
};
