var baseURL, blank, bytesToSize;

import Vue from 'vue';

import Moment from 'moment';

import Numeral from 'numeral';

baseURL = process.env.VUE_APP_SERVER_URL;

Vue.filter('format', (value, format) => {
  var ary, city, cnt, i, key, len, query, ref, ref1, ref2, state, val, zip;
  if (value || format === 'currency0') {
    switch (format) {
      case 'addressUrl':
        cnt = 0;
        ref1 = ['address', 'city', 'state', 'zip'];
        for (i = 0, len = ref1.length; i < len; i++) {
          key = ref1[i];
          if ((ref2 = value[key]) != null ? ref2.length : void 0) {
            cnt++;
          }
        }
        if (cnt === 4) {
          query = encodeURI(`${value.address}, ${value.city}, ${value.state} ${value.zip}`);
          return `https://www.google.com/maps/search/?api=1&query=${query}`;
        } else {
          return "";
        }
        break;
      case 'capitalize':
        return value.charAt(0).toUpperCase() + value.slice(1);
      case 'csz':
        ({city, state, zip} = value);
        ary = [city, state, zip].map((val) => {
          return `${val || ''}`.trim().replace(/  +/g, ' ');
        });
        val = "";
        if (ary[0]) {
          val += ary[0];
        }
        if (val && ary[1]) {
          val += ", ";
        }
        if (ary[1]) {
          val += ary[1];
        }
        if (val && ary[2]) {
          val += "  ";
        }
        if (ary[2]) {
          val += ary[2];
        }
        return val;
      case 'currency':
        return Numeral(value).format('$0,0.00');
      case 'currency0':
        return Numeral(value).format('$0,0.00'); // allows $0.00 to pass through
      case 'date':
        if (value === 'Invalid date') {
          return '';
        } else {
          return Moment(value, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
        }
        break;
      case 'dateIso':
        return Moment(value, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD');
      case 'dateLocal':
        return Moment.utc(value, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YYYY');
      case 'dateString':
        return Moment(value, 'YYYY-MM-DD hh:mm:ss').format('MMM Do, YYYY');
      case 'dateTime':
        return Moment(value, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY h:mma');
      case 'fileSize':
        return bytesToSize(value);
      case 'fromUnix':
        return Moment.unix(value);
      case 'name':
        if (typeof value === 'string') {
          return value.namify();
        } else {
          return value;
        }
        break;
      case 'mime':
        return {
          gif: 'image/gif',
          jpg: 'image/jpeg',
          pdf: 'application/pdf',
          png: 'image/png'
        }[value] || 'n/a';
      case 'newlinesToBrs':
        return value.replace(/\n/g, '<br>\n');
      case 'number':
        return Numeral(value).format('0.00');
      case 'relativeDate':
        val = Moment(value);
        ref = Moment();
        if (ref.diff(val, 'hours') <= 24) {
          return 'Today';
        } else if (ref.diff(val, 'hours') <= 48) {
          return 'Yesterday';
        } else if (val.year() === ref.year()) {
          return val.format('MMM Do');
        } else {
          return val.format('MMM Do, YYYY');
        }
        break;
      case 'relativeTimestamp':
        val = Moment(value);
        ref = Moment().startOf;
        return val.format(val.isAfter(ref('day')) ? 'h:mm a' : val.isAfter(ref('year')) ? 'MMM D' : 'M/DD/YY');
      case 'sinceMonthYear':
        return "Since " + Moment.utc(value, 'YYYY-MM-DD hh:mm:ss').local().format('MMMM YYYY');
      case 'string':
        return value.replace(/(<([^>]+)>)/ig, ' ');
      case 'time':
        return Moment.utc(value, 'YYYY-MM-DD hh:mm:ss').local().format('h:mma');
      case 'timeFromNow':
        return Moment.utc(value).fromNow();
      case 'fullUrl':
        return `${baseURL}${value}`;
    }
  }
});

Vue.filter('account', (account, format) => {
  var age, firstName, lastName, mdy, name, preferredName, sex;
  if (account) {
    switch (format) {
      case 'name':
        ({firstName, preferredName, lastName} = account);
        if (blank(firstName)) {
          name = `${preferredName || ''}`;
        } else if (!blank(preferredName)) {
          name = `${firstName} \"${preferredName || ''}\"`;
        } else {
          name = firstName;
        }
        return name = `${name} ${lastName}`;
      case 'summary':
        if (!account.dob) {
          return '';
        }
        mdy = Moment(account.dob).format('MM/DD/YYYY');
        age = Moment().diff(Moment(account.dob), 'years');
        sex = account.sex ? ` ${account.sex}` : "";
        return `${mdy} (${age}y${sex})`;
    }
  }
});

Vue.filter('name', (user, format) => {
  var name;
  if (!user) {
    return;
  }
  switch (format) {
    case 'first':
      return user.firstName;
    case 'firstLast':
      return `${user.firstName} ${user.lastName}`;
    case 'full':
      name = `${user.firstName} ${user.lastName}`;
      if (user.title) {
        return `${name}, ${user.title}`;
      } else {
        return name;
      }
      break;
    case 'last':
      return user.lastName;
  }
});

Vue.filter('stage', function(stage, style) {
  var i, j, len, len1, obj, ref1, ref2, val;
  if (stage === 'N/A') {
    return 'N/A';
  }
  ref1 = $state.get('/config/config/statuses', []);
  for (i = 0, len = ref1.length; i < len; i++) {
    obj = ref1[i];
    ref2 = obj.val;
    for (j = 0, len1 = ref2.length; j < len1; j++) {
      val = ref2[j];
      if (val === stage) {
        switch (style) {
          case 'both':
            return `${obj.key}: ${stage}`;
          case 'status':
            return obj.key;
          default:
            return stage;
        }
      }
    }
  }
  return stage;
});

({
  ageFromDob: function(dob) {
    var ageDate, ageDifMs;
    ageDifMs = Date.now() - Date.parse(dob);
    ageDate = new Date(ageDifMs);
    // NOTE: This age isn't accurate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
});

blank = function(obj) {
  switch (typeof obj) {
    case 'string':
      return obj.trim().length === 0;
    case void 0:
    case null:
      return true;
    default:
      return !obj;
  }
};

bytesToSize = function(count) {
  var scale, sizes;
  if (+count > 1) {
    scale = Math.log(count) / Math.log(1024) | 0;
    if (scale) {
      count = (count / 1024 ** scale).toFixed(1);
    }
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    return `${count} ${sizes[scale]}`;
  } else if (count === 1) {
    return '1 Byte';
  } else {
    return 'n/a';
  }
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.namify = function() {
  var str;
  if (!((str = this.toString()) && (str = str.replace(/  +/g, ' ').trim().toLowerCase()).length)) {
    return '';
  }
  return str.replace(/([^\w']+|'(?!s\b)|^'?)(mc|mac(?=d[ao][a-k,m-z][a-z])|d'|o'|j(?=[bc]\b)|[bc](?=j\b))?(\#\d[-\d]*|(?:i{1,3}|iv|v|llc)\b)?(\w)?/ig, function(...$) { // 1: find specific word breaks
    // non-word, non-apostrophe
    // apostrophes followed by s
    // beginning of string
    // 2: optionally capitalize certain prefixes
    // McNamara
    // MacDonald, but not Macon
    // J[BC]
    // [BC]J
    // 3: optionally uppercase certain numbers and suffixes
    // 4: uppercase next word character
    var ref1, ref2, ref3;
    return `${$[1]}${((ref1 = $[2]) != null ? ref1.capitalize() : void 0) || ''}${((ref2 = $[3]) != null ? ref2.toUpperCase() : void 0) || ''}${((ref3 = $[4]) != null ? ref3.capitalize() : void 0) || ''}`;
  }).replace(/(?<=.)(and|at|as|of|the|in|on|for|to|by|de)\b/ig, function(...$) {
    return `${$[1].toLowerCase()}`;
  }).replace(/\b([b-df-hj-np-tv-z]{2,5}|p?llc|dba|usa|id|ihop|ihss|ilr|fbo|ipc|ucla|ca|mri)\b/ig, function(...$) {
    return `${$[1].toUpperCase()}`;
  }).replace(/\b((?:s|j)r)\s*$/ig, function(...$) {
    return `${$[1][0]}${$[1].slice(1).toLowerCase()}`;
  });
};
