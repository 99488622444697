import Moment from 'moment';

import Utils from '~utils';

export default {
  props: {
    eventToBeEdited: Object
  },
  data: function() {
    return {
      event: {},
      createMode: true
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    eventTypes: function() {
      return this.get('/config/config/eventTypes', []);
    },
    statuses: function() {
      return this.get('/config/config/statuses', []);
    }
  },
  watch: {
    eventToBeEdited: function(event) {
      if (event) {
        this.event = event;
        this.event.date = Moment(event.createdAt, 'YYYY-MM-DD HH:mm:ss UTC').format('YYYY-MM-DD');
        return this.createMode = false;
      } else {
        return this.createMode = true;
      }
    }
  },
  methods: {
    createEvent: async function() {
      var index, item, response, time;
      if (this.$refs.form.check()) {
        item = Utils.clone(this.event);
        time = Moment().utc().format('HH:mm:ss');
        item.createdAt = Moment(`${item.date} ${time}`, 'YYYY-MM-DD hh:mm:ssa').format('YYYY-MM-DD HH:mm:ss UTC');
        item.accountId = this.account.id;
        delete item.date;
        if (this.createMode) {
          if (response = (await this.$api.createEvent(item))) {
            this.$set(this.account, 'status', response.data.accountStatus);
            this.$set(this.account, 'contactAt', response.data.contactAt);
            item.id = response.data.id;
            item.userId = this.get('/user/user/id');
            this.account.events.unshift(item);
            this.account.userIds[item.userId] = this.get('/user/user.name');
            this.reset();
            return true;
          }
        } else {
          if (response = (await this.$api.updateEvent(item))) {
            this.$set(this.account, 'status', response.data.accountStatus);
            this.$set(this.account, 'contactAt', response.data.contactAt);
            if (~(index = this.account.events.findIndex((e) => {
              return e.id === item.id;
            }))) {
              this.$set(this.account.events, index, item);
              this.reset();
              return true;
            }
          }
        }
      }
    },
    deleteEvent: async function() {
      var index;
      if ((await this.$api.deleteEvent({
        id: this.event.id
      }))) {
        index = this.account.events.findIndex((e) => {
          return e.id === this.event.id;
        });
        this.account.events.splice(index, 1);
        return true;
      }
    },
    reset: function() {
      return this.event = {
        date: Moment().format('YYYY-MM-DD'),
        kind: 'communications',
        userId: this.get('/user/user/id'),
        status: this.get('/accounts/account/status')
      };
    }
  }
};
