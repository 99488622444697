import Utils from '~/utils';

import Draggable from 'vuedraggable';

import AccountContactAdd from './AccountContactAdd';

import AccountContactForm from './AccountContactForm';

export default {
  components: {Draggable, AccountContactAdd, AccountContactForm},
  data: function() {
    return {
      cloned: []
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    contacts: {
      get: function() {
        var contacts;
        contacts = this.get('/accounts/account/contacts');
        if (!contacts) {
          return [];
        }
        this.cloned = Utils.clone(contacts);
        return contacts;
      },
      set: async function(contacts) {
        var sortedContacts;
        if (!Array.isArray(contacts)) {
          contacts = [];
        }
        this.set('/accounts/account/contacts', contacts);
        sortedContacts = contacts.map((item) => {
          return item.id;
        }).join(',');
        return (await this.$api.accountContacts({
          id: this.account.id,
          sortedContacts
        }));
      }
    }
  },
  methods: {
    contactKind: function(contact) {
      var icon, map, text;
      if ((map = this.get('/config/config/contactIconMap')) && (icon = map[text = contact.kind])) {
        return {text, icon}; // ok to return on failure or should we have a fallback?
      } else {
        return {};
      }
    },
    openAddContactDialog: function() {
      return this.run('/ui/toggleDialog', {
        id: 'add-contact-dialog',
        open: true
      });
    },
    showAddress: function(obj) {
      return !!(['address', 'city', 'state', 'zip'].find((key) => {
        return obj[key] > "";
      }));
    },
    updateContact: async function(n) {
      if (this.$refs.editContactForm[0].$refs.form.check()) {
        if ((await this.$api.updateContact(this.cloned[n]))) {
          this.$set(this.contacts, n, this.cloned[n]);
          return true;
        }
      }
    },
    removeContact: async function(n) {
      var contact;
      if (contact = this.contacts[n]) {
        if ((await this.$api.removeContact({
          id: contact.id,
          accountId: this.account.id
        }))) {
          return this.contacts.splice(n, 1);
        }
      }
    }
  }
};
