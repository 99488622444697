export default {
  props: {
    label: String
  },
  data: function() {
    return {
      editMode: false
    };
  }
};
