import Vue from 'vue';

import Utils from '~/utils';

import Moment from 'moment';

import {
  orderBy
} from 'lodash';

import Pagination from './AccountsListPagination';

export default {
  name: 'AccountList',
  components: {Pagination},
  props: {
    panelId: String
  },
  data: function() {
    return {
      accounts: [],
      sortKey: this.get('/ui/accountListTable/sortKey'),
      order: this.get('/ui/accountListTable/order')
    };
  },
  computed: {
    accountList: function() {
      return this.get('/accounts/list');
    }
  },
  watch: {
    accountList: {
      immediate: true,
      handler: function(list) {
        var a, accounts, i, len;
        accounts = Utils.clone(list);
        for (i = 0, len = accounts.length; i < len; i++) {
          a = accounts[i];
          a.name = `${a.firstName} ${a.lastName}`;
          a.statusStage = `${Vue.filter('stage')(a.status, 'status')} - ${a.status}`;
        }
        this.accounts = accounts;
        return this.sortAccounts();
      }
    }
  },
  methods: {
    viewAccount: function(id) {
      return this.$router.push({
        name: 'viewAccount',
        params: {
          id: id
        }
      });
    },
    thClass: function(key) {
      var classes;
      classes = ['sortable'];
      if (this.sortKey === key) {
        classes.push('active');
      }
      if (this.sortKey === key && this.order === -1) {
        classes.push('inverse');
      }
      return classes;
    },
    sortBy: function(key) {
      if (this.sortKey === key) {
        this.order = this.order * -1;
      }
      this.sortKey = key;
      this.sortAccounts();
      this.set('/ui/accountListTable/sortKey', this.sortKey);
      return this.set('/ui/accountListTable/order', this.order);
    },
    sortAccounts: function() {
      var sorted;
      sorted = orderBy(this.accounts, this.sortKey, this.order === 1 ? 'asc' : 'desc');
      this.accounts = sorted;
      return this.set('/ui/accountListTable/accounts', this.accounts);
    },
    lastContactClass: function(lastContact) {
      if (Moment(lastContact).isBefore(Moment().subtract(30, 'days'))) {
        return 'older-than-1-month';
      }
    }
  }
};
