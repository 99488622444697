import Vue from 'vue';

import Router from 'vue-router';

Vue.use(Router);

import Auth from '~/components/app/Auth';

import App from '~/components/app/App';

import Account from '~/components/accounts/Account';

import Dashboard from '~/components/dashboard/Dashboard';

import AccountsList from '~/components/accounts/AccountsList';

// import Mail       from '~/components/mail/Mail'
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // TODO: add a helper to simplify this
  routes: [
    {
      path: '/',
      component: App,
      children: [
        {
          path: '/',
          component: Dashboard
        },
        {
          path: '/accounts',
          component: AccountsList
        },
        {
          path: 'accounts/:id',
          component: Account,
          name: 'viewAccount'
        }
      ]
    },
    {
      // { path: '/mail'     , redirect: '/mail/inbox' }
      // { path: '/mail/:folder/:threadId?'
      //                     , component: Mail }
      path: '/signin',
      component: Auth
    },
    {
      path: '/forgot',
      component: Auth
    },
    {
      path: '/reset',
      component: Auth
    },
    {
      path: '/signup',
      component: Auth,
      children: [
        {
          path: 'info',
          component: Auth
        },
        {
          path: 'complete',
          component: Auth
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});
