export default {
  props: {
    item: String,
    onSave: Function
  },
  computed: {
    itemCapitalized: function() {
      return this.item.charAt(0).toUpperCase() + this.item.substring(1);
    }
  }
};
