import Utils from '~utils';

export default {
  props: {
    event: Object
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    summary: function() {
      var action, article;
      article = /^[aeiou]/i.test(this.event.name) ? 'an' : 'a';
      action = (function() {
        var ref;
        switch (this.event.name) {
          // TODO: should these be stored as lower case?
          case 'Call':
          case 'Email':
          case 'Fax':
          case 'Mail':
            return `added ${article} <strong>${this.event.name}</strong> event`;
          case 'Other':
            return "added an event";
          case 'Contact':
          case 'Transaction':
            return `added ${article} <strong>${this.event.name}</strong>`;
          case 'account updated':
            return "updated account profile";
          case 'Document':
            if ((ref = this.event.data) != null ? ref.filename : void 0) {
              return `uploaded the document <span class='target'>${this.event.data.filename}</span>`;
            } else {
              return `uploaded ${article} document`;
            }
            break;
          default:
            return ''; //!# ADD SOMETHING HERE...
        }
      }).call(this);
      return `<span class='user'>${this.account.userIds[this.event.userId]}</span> ${action}`;
    },
    statusStyles: function() {
      var hash, list, pick;
      list = this.get('/config/config/statusColors', {}); // 16-colors
      hash = Utils.strhash(this.event.status);
      pick = list[hash % 16] || '#2ECC70';
      return {
        backgroundColor: pick,
        color: Utils.foregroundColor(pick)
      };
    }
  },
  methods: {
    openEditEventDialog: function() {
      if (window.getSelection().toString().length === 0) {
        this.$emit('eventToBeEdited', Utils.clone(this.event));
        return this.run('/ui/toggleDialog', {
          id: 'event-dialog',
          open: true
        });
      }
    }
  }
};
