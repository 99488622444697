export default {
  props: {
    panelId: String
  },
  data: function() {
    return {
      batches: [],
      loading: true,
      progress: 'start',
      selected: null,
      summary: ""
    };
  },
  watch: {
    progress: function(val) {
      if (val === 'review') {
        return this.cleanBatch();
      }
    }
  },
  created: function() {
    return this.getBatches();
  },
  methods: {
    close: function() {
      return this.$refs.panel.panel.close();
    },
    reset: function() {
      this.selected = null;
      return this.progress = 'start';
    },
    getBatches: async function() {
      var response;
      response = (await this.$api.batches());
      this.batches = response.data;
      return this.loading = false;
    },
    cleanBatch: async function() {
      var response;
      this.loading = true;
      response = (await this.$api.cleanBatch(this.selected));
      this.summary = response.data.summary;
      return this.loading = false;
    },
    importBatch: async function() {
      var error, response;
      this.loading = true;
      try {
        response = (await this.$api.importBatch(this.selected)); // don't need result
        response = (await this.$api.batches());
        this.batches = response.data;
        this.progress = 'success';
      } catch (error1) {
        error = error1;
        this.progress = 'failure';
      }
      return this.loading = false;
    }
  }
};
