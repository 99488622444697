import Draggable from 'vuedraggable';

import AccountTagsAdd from './AccountTagsAdd';

export default {
  components: {Draggable, AccountTagsAdd},
  data: function() {
    return {
      tagToBeRemoved: null
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    tags: {
      get: function() {
        return this.get('/accounts/account/tags');
      },
      set: async function(tags) {
        var sortedTags;
        this.set('/accounts/account/tags', tags);
        sortedTags = tags.map((item) => {
          return item.id;
        }).join(',');
        return (await this.$api.accountTags({
          id: this.account.id,
          sortedTags
        }));
      }
    }
  },
  methods: {
    openAddTagDialog: function() {
      return this.run('/ui/toggleDialog', {
        id: 'add-tag-dialog',
        open: true
      });
    },
    confirmTagRemoval: function(tag) {
      this.tagToBeRemoved = tag;
      return this.run('/ui/toggleDialog', {
        id: 'confirm-tag-removal-dialog',
        open: true
      });
    },
    removeTag: async function() {
      var index, tag;
      tag = this.tagToBeRemoved;
      if ((await this.$api.removeTag({
        id: tag.id,
        accountId: this.account.id
      }))) {
        index = this.tags.findIndex(function(e) {
          return e.id === tag.id;
        });
        this.tags.splice(index, 1);
        this.tagToBeRemoved = null;
        return true;
      }
    },
    onDragStart: function() {
      return document.querySelector('.tippy-popper').style.display = 'none';
    },
    onDragEnd: function() {
      return document.querySelector('.tippy-popper').style.display = 'block';
    }
  }
};
