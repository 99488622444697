import Vue from 'vue';

export default new Vue({
  data: function() {
    return {
      user: {}
    };
  },
  methods: {
    load: async function() {
      return this.user = ((await this.$api.user())).data;
    }
  }
});
