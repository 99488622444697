import AccountOverview from './AccountOverview';

import AccountTimeline from './AccountTimeline';

import AccountDocuments from './AccountDocuments';

export default {
  name: 'Account',
  components: {AccountOverview, AccountTimeline, AccountDocuments},
  watch: {
    '$route': 'onRouteChange'
  },
  created: function() {
    return this.onRouteChange();
  },
  methods: {
    onRouteChange: function() {
      return this.run('/accounts/get', this.$route.params.id);
    }
  }
};
