var baseURL;

baseURL = process.env.VUE_APP_SERVER_URL;

export default {
  props: {
    size: String,
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes: function() {
      return {
        'ui-avatar--small': this.size === 'small',
        'ui-avatar--large': this.size === 'large'
      };
    },
    src: function() {
      var url;
      url = baseURL;
      // TODO: Cache avatars so we don't make a million queries for them
      // url += "/v1/avatar/" + if @user.id and @user.avatar
      // then "#{@user.id}.#{@user.avatar}"
      // else "default.png"
      url += "/v1/avatar/" + (this.user.id ? `${this.user.id}.png` : "default.png");
      return 'https://cdn4.vectorstock.com/i/1000x1000/18/98/user-icon-female-person-symbol-profile-avatar-sign-vector-18991898.jpg';
    }
  }
};
