import {
  MDCMenuSurface
} from '@material/menu-surface';

export default {
  props: {
    options: Array
  },
  data: function() {
    return {
      menu: null
    };
  },
  mounted: function() {
    return this.menu = new MDCMenuSurface(this.$refs.menu);
  },
  methods: {
    open: function() {
      return this.menu.open = true;
    }
  }
};
