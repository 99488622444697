import Moment from 'moment';

export default {
  props: {
    input: String,
    required: Boolean
  },
  data: function() {
    return {
      month: null,
      day: null,
      year: null,
      label: 'Date of Birth',
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
  },
  watch: {
    '$data': {
      handler: function() {
        return this.onChange();
      },
      deep: true
    }
  },
  created: function() {
    var moment;
    moment = Moment(this.input, 'YYYY-MM-DD');
    if (moment.isValid()) {
      this.month = moment.format('MMM');
      this.day = moment.format('D');
      return this.year = moment.format('YYYY');
    }
  },
  methods: {
    onChange: function() {
      var moment, ref, valid;
      valid = false;
      if (this.month && this.day && ((ref = this.year) != null ? ref.length : void 0) > 1) {
        moment = Moment(`${this.month} ${this.day}, ${this.year}`, 'MMM DD, YYYY');
        valid = moment.isValid();
      }
      return this.$emit('update:input', valid ? moment.format('YYYY-MM-DD') : '');
    },
    check: function(form) {
      var child, dref, dval, i, len, mref, mval, ref, valid, yref, yval;
      // field-level constraints
      if (this.$children) {
        ref = this.$children;
        for (i = 0, len = ref.length; i < len; i++) {
          child = ref[i];
          if ((typeof child.check === "function" ? child.check(form) : void 0) === false) {
            valid = false;
          }
        }
      }
      // get m/d/y values
      mval = (mref = this.$refs.month).model;
      dval = (dref = this.$refs.day).model;
      yval = (yref = this.$refs.year).model;
      // p ["DOB-1:", "[#{mval}] [#{dval}] [#{yval}]"]

      // if checking the form, or if all fields have values
      if (form || (mval && dval && yval)) {
        // verify dob if present or required
        if (this.required || (mval || dval || yval)) {
          // convert m/d/y to numbers
          mval = +this.months.indexOf(mval) + 1;
          dval = +dval;
          yval = +yval;
          // flag invalid values
          mval > 0 || (mref.valid = valid = false);
          dval > 0 || (dref.valid = valid = false);
          yval > 0 || (yref.valid = valid = false);
          // p ["DOB-2:", "[#{mval}] [#{dval}] [#{yval}]"]

          // if everything check out, verify leap year
          if (valid !== false) {
            if (!Moment(`${mval}/${dval}/${yval}`, "M/D/Y").isValid()) {
              dref.valid = valid = false;
            }
          }
        }
      }
      // our verdict...
      // p ["DOB-3:", valid isnt false]
      return this.valid = valid !== false;
    }
  }
};
