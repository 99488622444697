export default {
  name: 'Base',
  computed: {
    user: function() {
      return this.get('/auth/user');
    }
  },
  watch: {
    user: function(user) {
      var redirect, url;
      if (!(user && Object.keys(user).length)) {
        return this.$router.replace('/signin');
      } else if (this.$route.path.includes('/signin')) {
        redirect = '/';
        url = new URL(window.location.href);
        if (url.searchParams.has('redirect')) {
          redirect = url.searchParams.get('redirect');
        }
        return this.$router.replace(redirect);
      }
    }
  },
  created: function() {
    return this.run('/config/load');
  }
};
