export default {
  props: {
    marginTop: {
      type: Boolean,
      default: false
    },
    heading: String,
    removeBodyBg: {
      type: Boolean,
      default: false
    },
    removeBodyStyling: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes: function() {
      return {
        'margin-top': this.marginTop
      };
    },
    bodyClasses: function() {
      return {
        'no-bg': this.removeBodyBg,
        'no-styling': this.removeBodyStyling
      };
    }
  }
};
