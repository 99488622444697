var frac, hide;

import Tippy from 'tippy.js';

frac = function(val, n = 1) {
  if (n > 1) {
    return Math.round(val * n) / n;
  } else if (n === 1) {
    return val | 0;
  } else {
    return val;
  }
};

hide = false;

export default {
  props: {
    onRemove: Function,
    onSave: Function
  },
  data: function() {
    return {
      tippy: null,
      editMode: false,
      loading: false
    };
  },
  mounted: function() {
    return this.tippy = Tippy(this.$refs.trigger, {
      animation: 'fade',
      animateFill: false,
      a11y: false,
      content: this.$refs.content,
      delay: [500, 0],
      duration: [250, 200],
      interactive: true,
      interactiveBorder: 5,
      onShow: () => {
        return window.addEventListener('keyup', this.onKeyup);
      },
      onHide: () => {
        if (!(!this.editMode || hide)) {
          return false;
        }
        return window.removeEventListener('keyup', this.onKeyup);
      },
      placement: 'top-start',
      theme: 'popover',
      zIndex: 10,
      popperOptions: {
        modifiers: {
          popperOffsets: {
            order: 50,
            enabled: true,
            fn: function(data, options) {
              var b, bcr, css, dom, full, h, half, l, main, pop, pos, r, ref, shift, start, t, vert, w;
              dom = data.instance.popper;
              ref = data.offsets.reference;
              pos = data.placement.split('-')[0];
              css = dom.ownerDocument.defaultView.getComputedStyle(dom);
              bcr = dom.getBoundingClientRect();
              [t, r, b, l, w, h] = 'top right bottom left width height'.split(' ');
              vert = pos === t || b; // top/bottom?
              main = pos === t || l; // top/left?
              [start, half] = vert ? [l, w] : [
                t,
                h // used to center
              ];
              [shift, full] = vert ? [t, h] : [
                l,
                w // used to offset
              ];
              pop = {
                [t]: ref[t],
                [l]: ref[l],
                [w]: bcr[w] + parseFloat(css.marginLeft || 0) + parseFloat(css.marginRight || 0),
                [h]: bcr[h] + parseFloat(css.marginTop || 0) + parseFloat(css.marginBottom || 0),
                position: data.offsets.popper.position
              };
              pop[start] += (ref[half] - pop[half]) / 2; // center on axis
              pop[shift] += main ? -pop[full] : ref[full];
              data.offsets.popper = pop;
              return data;
            }
          },
          computeStyle: {
            order: 850,
            enabled: true,
            fn: function(data, options) {
              var b, dom, dpr, gpu, h, inner, l, outer, pop, r, ref, styles, t, w, x, y;
              dom = data.instance.popper;
              ref = data.offsets.reference;
              pop = data.offsets.popper;
              outer = dom.getBoundingClientRect();
              inner = dom.querySelector('.content-inner').firstChild.getBoundingClientRect();
              [t, r, b, l, w, h] = 'top right bottom left width height'.split(' ');
              x = pop[l] - inner[l] + outer[l];
              y = ref[t] - inner[t] + outer[t];
              // use gpu if possible
              dpr = window.devicePixelRatio;
              gpu = ![x, y].find((n) => {
                return Math.abs(n - frac(n, dpr)) > 0.001;
              });
              styles = gpu ? {
                transform: `translate3d(${x}px, ${y}px, 0)`,
                top: 0,
                left: 0,
                willChange: 'transform'
              } : {
                top: y,
                left: x,
                willChange: 'top, left'
              };
              data.attributes = {
                'x-placement': data.placement,
                ...data.attributes
              };
              data.styles = {...data.styles, ...styles};
              data.arrowStyles = {...data.offsets.arrow, ...data.arrowStyles};
              return data;
            }
          }
        }
      }
    });
  },
  beforeDestroy: function() {
    return this.dismiss();
  },
  methods: {
    toggleMode: async function() {
      var success;
      if (this.editMode) {
        this.loading = true;
        success = (await this.run(this.onSave));
        this.loading = false;
        if (!success) {
          return;
        }
      }
      return this.editMode = !this.editMode;
    },
    remove: async function() {
      return (await this.onRemove()) && this.dismiss();
    },
    dismiss: function() {
      hide = true;
      this.tippy.hide();
      return setTimeout((() => {
        return this.editMode = hide = false;
      }), 100); // prevent 'edit' jitter before hide()
    },
    onKeyup: function(e) {
      if (e.key === 'Escape') {
        return this.dismiss();
      }
    }
  }
};
