import Vue from 'vue';

export default new Vue({
  data: function() {
    return {
      account: {},
      wait: null,
      list: [],
      tags: [],
      page: 1,
      show: 100,
      size: 0
    };
  },
  methods: {
    get: async function(id) {
      this.set('wait', 'loading');
      this.account = ((await this.$api.account(id))).data;
      return this.set('wait', null);
    },
    create: async function(account) {
      var err, response;
      try {
        response = (await this.$api.createAccount(account));
        this.$toasted.show('Account created');
        return response.data.id;
      } catch (error) {
        err = error;
        p(["Error:", err]);
        this.$toasted.error('Try again later');
        return false;
      }
    },
    update: async function(account) {
      var err;
      try {
        await this.$api.updateAccount(account);
        this.$toasted.show('Account updated');
        return true;
      } catch (error) {
        err = error;
        p(["Error:", err]);
        this.$toasted.error('Try again later');
        return false;
      }
    },
    getList: async function(params) {
      var response;
      this.set('wait', 'loading');
      response = (await this.$api.accountsList({
        ...params,
        page: this.page
      }));
      this.set('/accounts/list', response.data.list);
      this.set('/accounts/show', response.data.show);
      this.set('/accounts/size', response.data.size);
      this.set('/accounts/tags', response.data.tags);
      return this.set('wait', null);
    }
  }
});
