import Moment from 'moment';

import {
  orderBy
} from 'lodash';

import AccountTimelineEventDialog from './AccountTimelineEventDialog';

import AccountTimelineEventDetails from './AccountTimelineEventDetails';

export default {
  components: {AccountTimelineEventDialog, AccountTimelineEventDetails},
  data: function() {
    return {
      activeType: 'all',
      eventToBeEdited: null,
      hiddenEvents: null
    };
  },
  computed: {
    account: function() {
      return this.get('/accounts/account');
    },
    eventsByDate: function() {
      var list, listLengthStart;
      list = this.account.events || [];
      list = orderBy(list, 'createdAt', 'desc');
      if (this.activeType !== 'all') {
        list = list.filter((item) => {
          return item.kind === this.activeType;
        });
      }
      if (this.hiddenEvents !== -1) {
        this.hiddenEvents = 0;
        listLengthStart = list.length;
        list = list.filter((item) => {
          return item.createdAt > Moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss UTC');
        });
        if (listLengthStart > list.length) {
          this.hiddenEvents = listLengthStart - list.length;
        }
      }
      return list.reduce((sets, item) => {
        var date;
        date = item.createdAt.split(' ')[0];
        (sets[date] || (sets[date] = [])).push(item);
        return sets;
      }, {});
    },
    hasEvents: function() {
      return Object.entries(this.eventsByDate).length > 0 && this.eventsByDate.constructor === Object;
    }
  },
  // created: ->
  //   @$socket.on 'new::event', (event) =>
  //     if event.account_id is @account.id then @account.events.unshift(event)
  methods: {
    openNewEventDialog: function() {
      this.eventToBeEdited = null;
      return this.run('/ui/toggleDialog', {
        id: 'event-dialog',
        open: true
      });
    }
  }
};
