import Vue from 'vue';

export default new Vue({
  data: function() {
    return {
      counter: {
        fizz: 0 // TODO: not needed, but part of some vue-state testing...
      },
      dialogs: {},
      drawer: false,
      panels: [],
      accountListTable: {
        sortKey: 'sourceName',
        order: 1,
        accounts: []
      }
    };
  },
  methods: {
    toggleDialog: function(dialog) {
      return Vue.set(this.dialogs, dialog.id, dialog.open);
    },
    openPanel: function(panel) {
      return this.panels.push(panel);
    },
    closePanel: function(panelId) {
      var index;
      index = this.panels.findIndex((p) => {
        return p.id === panelId;
      });
      return this.panels.splice(index, 1);
    },
    closePanels: function() {
      this.panels = [];
      return $state.$emit('closePanels');
    }
  }
});
