var code, name, uuid;

import Vue from 'vue';

Vue.config.productionTip = false;

import './directives';

import './filters';

import '@fortawesome/fontawesome-pro/css/all.css';

import './styles/app.styl';

import '~/components/global/Toasted';

import globalComponents from './components/global/*.vue';

for (name in globalComponents) {
  code = globalComponents[name];
  Vue.component(name, code.default || code);
}

import router from './router';

import api from './api';

import VueState from './state/vue-state';

import state from './state';

Vue.use(VueState, {
  state,
  global: '$state',
  devtools: true
});

import Base from '~/components/app/Base';

// generate uuid for all components
// TODO: Is this needed? See https://stackoverflow.com/a/34957052
uuid = 0;

Vue.mixin({
  beforeCreate: function() {
    return this.uuid = (uuid++).toString();
  }
});

import MQ from 'vue-match-media';

Vue.use(MQ);

// launch app
window.p = function(...args) {
  var arg, i, len, results;
  results = [];
  for (i = 0, len = args.length; i < len; i++) {
    arg = args[i];
    results.push(console.log(arg));
  }
  return results;
};

window.sleep = function(secs) {
  return new Promise((resolve) => {
    return setTimeout(resolve, 1000 * secs);
  });
};

window.app = new Vue({
  data: function() {
    var data, k, ref, v;
    data = {};
    ref = $state._data;
    for (k in ref) {
      v = ref[k];
      data[k] = v._data;
    }
    return data; //!# TODO: automate this?
  },
  router: router,
  mq: {
    mobile: '(max-width: 991px)',
    desktop: '(min-width: 992px)'
  },
  render: (h) => {
    return h(Base, {
      attrs: {
        id: 'base'
      }
    });
  }
}).$mount('#base');
