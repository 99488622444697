import {
  MDCCheckbox
} from '@material/checkbox';

import {
  MDCFormField
} from '@material/form-field';

export default {
  props: {
    id: String,
    name: String,
    input: Boolean,
    label: String
  },
  data: function() {
    return {
      model: this.input
    };
  },
  watch: {
    input: function(value) {
      return this.model = value;
    },
    model: function(model) {
      return this.$emit('update:input', model);
    }
  },
  mounted: function() {
    var checkbox, formField;
    formField = new MDCFormField(document.querySelector(`#${this.id}`));
    checkbox = new MDCCheckbox(document.querySelector(`#${this.id}-checkbox`));
    return formField.input = checkbox;
  }
};
