import Moment from 'moment';

import AccountPatientForm from './AccountPatientForm';

export default {
  components: {AccountPatientForm},
  data: function() {
    return {
      account: {}
    };
  },
  computed: {
    selectInput: function() {
      return this.get('/ui/selectInput', 'Unknown');
    }
  },
  created: function() {
    return this.reset();
  },
  watch: {
    selectInput: function(val) {
      var list;
      list = val.split(' ');
      if (list.length > 1) {
        this.$set(this.account, 'lastName', list.pop().namify());
      }
      return this.$set(this.account, 'firstName', list.join(' ').namify());
    }
  },
  methods: {
    createAccount: async function() {
      var id;
      if (this.$refs.createAccountForm.$refs.form.check()) {
        if (id = (await this.run('/accounts/create', this.account))) {
          this.reset();
          this.$router.replace({
            name: 'viewAccount',
            params: {id}
          });
          return true;
        }
      }
    },
    reset: function() {
      return this.account = {
        openedAt: Moment().format('YYYY-MM-DD'),
        sex: 'M'
      };
    }
  }
};
