import Utils from '~utils';

export default {
  props: {
    name: String,
    color: {
      type: String,
      default: '#e8e8e8'
    },
    note: String
  },
  computed: {
    styles: function() {
      return {
        backgroundColor: this.color,
        color: Utils.foregroundColor(this.color)
      };
    }
  }
};
