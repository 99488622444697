export default {
  name: 'Auth',
  data: function() {
    return {
      signinData: {},
      signupData: {},
      forgotEmail: null,
      resetSent: false,
      success: {},
      errors: {}
    };
  },
  computed: {
    config: function() {
      return this.get('/config/config/auth', {
        signin: {},
        signout: {}
      });
    }
  },
  watch: {
    '$route': function() {
      this.success = {};
      this.errors = {};
      if (this.$route.path === '/forgot') {
        return this.forgotEmail = this.signinData.email;
      }
    }
  },
  methods: {
    signin: async function() {
      var error;
      try {
        return (await this.run('/auth/signin', this.signinData));
      } catch (error1) {
        error = error1;
        return this.set('errors/signin', error);
      }
    },
    forgot: async function() {
      var error, response;
      try {
        this.signinData = {};
        response = (await this.run('/auth/forgot', this.forgotEmail));
        return this.resetSent = true;
      } catch (error1) {
        error = error1;
        return this.set('errors/forgot', error);
      }
    },
    signup: async function() {
      var error;
      try {
        return (await this.run('/auth/signup', this.signupData));
      } catch (error1) {
        error = error1;
        return this.set('errors/signup', error);
      }
    }
  }
};
