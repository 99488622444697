import {
  MDCDialog
} from '@material/dialog';

import {
  ResizeSensor
} from 'css-element-queries';

export default {
  props: {
    id: String,
    title: String,
    acceptButtonLabel: String,
    cancelButton: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: 'normal'
    },
    onSave: Function,
    onDelete: Function
  },
  data: function() {
    return {
      dialog: null,
      loading: false,
      deleteLabel: 'Delete'
    };
  },
  computed: {
    state: function() {
      return this.get(`/ui/dialogs/${this.id}`);
    },
    classes: function() {
      return {
        'mdc-dialog--narrow': this.width === 'narrow',
        'mdc-dialog--wide': this.width === 'wide'
      };
    }
  },
  watch: {
    state: function(dialog) {
      if (dialog) {
        this.$emit('opened');
        return this.dialog.open();
      } else {
        return this.dialog.close();
      }
    }
  },
  mounted: function() {
    var content;
    this.dialog = new MDCDialog(this.$refs.dialog);
    this.dialog.listen('MDCDialog:closed', () => {
      return this.close();
    });
    // hack to prevent dialog dropdowns from being cut off
    content = this.$refs.dialog.querySelector('.mdc-dialog__content');
    return ResizeSensor(content, () => {
      if (content.clientHeight < 300) {
        return content.style.overflow = 'visible';
      } else {
        return content.style.overflow = 'auto';
      }
    });
  },
  methods: {
    accept: async function() {
      var success;
      if (this.onSave) {
        try {
          this.loading = true;
          success = (await this.run(this.onSave));
          if (success) {
            return this.dialog.close();
          }
        } finally {
          this.loading = false;
        }
      } else {
        return this.dialog.close();
      }
    },
    deleteItem: async function() {
      var success;
      if (this.$refs.delete.$el.classList.contains('confirm')) {
        this.loading = true;
        success = (await this.run(this.onDelete));
        this.loading = false;
        if (success) {
          return this.dialog.close();
        }
      } else {
        this.deleteLabel = 'Really Delete?';
        return this.$refs.delete.$el.classList.add('confirm');
      }
    },
    close: function() {
      if (this.$refs.delete) {
        this.deleteLabel = 'Delete';
        this.$refs.delete.$el.classList.remove('confirm');
      }
      this.$emit('closed');
      return this.run('/ui/toggleDialog', {
        id: this.id,
        open: false
      });
    }
  }
};
