export default {
  props: {
    icon: String,
    weight: {
      type: String,
      default: 'regular'
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  computed: {
    classes: function() {
      var size, type;
      type = {
        solid: 'fas',
        regular: 'far',
        light: 'fal'
      };
      size = this.size ? ` fa-size--${this.size}` : "";
      return `${type[this.weight] || 'fa'} fa-${this.icon || 'empty-set'}${size}`;
    }
  }
};
